import React, { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  Button,
  Chip,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'

import AlertDialog from '../containers/AlertDialog'
import Drawer from '../containers/Drawer'
import FirestoreSchool from '../containers/FirestoreSchool'

import LabDrawerContent from '../components/LabDrawerContent'

import MenuButton from '../elements/MenuButton'
import * as time from '../utils/time'

export default () => {
  const navigate = useNavigate()
  const alertDialog = AlertDialog.useContainer()
  const drawer = Drawer.useContainer()
  const firestoreSchool = FirestoreSchool.useContainer()
  const { docData: school = {} } = firestoreSchool
  const { dataList: labList = [] } = firestoreSchool.useLabCol()
  const { dataList: userList = [] } = firestoreSchool.useUserCol()

  const teacherUserList = useMemo(
    () => userList.filter((x) => school.userRoleObj[x.id] === 'teacher'),
    [userList, school.userRoleObj],
  )

  function handleOpenDrawer(lab) {
    const btnLabel = lab ? '更新' : '登録'
    const defaultValue = lab?.info
    drawer.open({
      title: `ラボを${btnLabel}`,
      btnLabel,
      defaultValue,
      ContentComponent: <LabDrawerContent {...{ defaultValue, teacherUserList }} />,
      onSuccess: lab ? handleUpdate : handleCreate,
    })

    async function handleCreate(info) {
      firestoreSchool.addLab({ info }).then((labId) => {
        drawer.close()
        navigate(`./${labId}#invitations`)
      })
    }

    async function handleUpdate(info) {
      firestoreSchool.updateLab(lab.id, { info }).then(drawer.close)
    }
  }

  async function handleDelete(lab) {
    alertDialog.open({
      title: `ラボ「${lab.info?.name}」を削除しますか？`,
      description: '所属する生徒アカウントも削除されます。',
      cancelable: true,
      actions: [
        { label: '削除する', color: 'error', onClick: () => firestoreSchool.deleteLab(lab.id) },
      ],
    })
  }

  return (
    <Stack spacing={2} py={2} maxWidth='xl'>
      <Button
        variant='contained'
        color='primary'
        onClick={() => handleOpenDrawer()}
        sx={{ alignSelf: 'center' }}
      >
        ラボを登録
      </Button>

      <TableContainer variant='outlined' component={Paper} sx={{ width: '100%' }}>
        <Table>
          <TableHead sx={{ '& th': { fontSize: 'small', color: 'gray', padding: '8px 16px' } }}>
            <TableRow>
              <TableCell>ラボ名</TableCell>
              <TableCell>担当教員</TableCell>
              <TableCell>生徒アカウント</TableCell>
              <TableCell>登録日時</TableCell>
              <TableCell sx={{ width: 0 }} />
            </TableRow>
          </TableHead>
          <TableBody>
            {labList.map((lab) => (
              <TableRow
                key={lab.id}
                onClick={() => navigate(`./${lab.id}`)}
                sx={{ cursor: 'pointer', '&:hover': { bgcolor: 'background.focused' } }}
              >
                <TableCell>{lab.info?.name}</TableCell>
                <TableCell sx={{ maxWidth: '20rem' }}>
                  <Stack direction='row' spacing={1} flexWrap='wrap' useFlexGap>
                    {lab.info?.teacherUserIdArr?.map((userId) => (
                      <Chip
                        key={userId}
                        label={userList.find((x) => x.id === userId)?.info?.displayName}
                        variant='outlined'
                      />
                    ))}
                  </Stack>
                </TableCell>
                <TableCell sx={{ maxWidth: '20rem' }}>
                  <Stack direction='row' spacing={1} flexWrap='wrap' useFlexGap>
                    {lab.userIdArr?.map((userId) => (
                      <Chip
                        key={userId}
                        label={userList.find((x) => x.id === userId)?.info?.displayName}
                        variant='outlined'
                      />
                    ))}
                  </Stack>
                </TableCell>
                <TableCell>{time.toString(lab.createdAt)}</TableCell>
                <TableCell>
                  <MenuButton
                    items={[
                      {
                        label: '編集',
                        Icon: EditIcon,
                        color: 'primary',
                        onClick: () => handleOpenDrawer(lab),
                      },
                      {
                        label: '削除',
                        Icon: DeleteForeverIcon,
                        color: 'error',
                        onClick: () => handleDelete(lab),
                      },
                    ]}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
  )
}
