import React, { useState } from 'react'
import { Stack, Chip } from '@mui/material'

import Accordion from '../elements/Accordion'

import categoryList from '../utils/categoryList.json'

export default (props) => {
  const [categoryIdArr, setCategoryIdArr] = useState([])

  function handleClick(id) {
    setCategoryIdArr((prevIdArr) => {
      let newIdArr
      if (prevIdArr.includes(id)) {
        newIdArr = prevIdArr.filter((x) => x !== id)
      } else {
        newIdArr = prevIdArr.concat(id)
      }
      props.onChange(newIdArr)
      return newIdArr
    })
  }

  return (
    <Accordion title='興味のある分野を選ぶ' defaultExpanded>
      <Stack direction='row' spacing={1} flexWrap='wrap' useFlexGap>
        {categoryList.map((category) => (
          <Chip
            key={category.id}
            label={category.label}
            color={categoryIdArr.includes(category.id) ? 'error' : undefined}
            variant={categoryIdArr.includes(category.id) ? 'filled' : 'outlined'}
            onClick={() => handleClick(category.id)}
            sx={{ borderRadius: 2 }}
          />
        ))}
      </Stack>
    </Accordion>
  )
}
