import React from 'react'
import { useNavigate } from 'react-router-dom'
import {
  Button,
  Paper,
  Chip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Stack,
  Avatar,
  Typography,
} from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import ApartmentIcon from '@mui/icons-material/Apartment'

import AlertDialog from '../containers/AlertDialog'
import Drawer from '../containers/Drawer'
import Firestore from '../containers/Firestore'

import MentorDrawerContent from '../components/MentorDrawerContent'

import MenuButton from '../elements/MenuButton'

import * as time from '../utils/time'
import categoryList from '../utils/categoryList.json'

export default () => {
  const navigate = useNavigate()
  const alertDialog = AlertDialog.useContainer()
  const drawer = Drawer.useContainer()
  const firestore = Firestore.useContainer()
  const { dataList: mentorList = [] } = firestore.useMentorCol()

  function handleOpenDrawer(mentor) {
    const btnLabel = mentor ? '更新' : '登録'
    drawer.open({
      title: `メンターを${btnLabel}`,
      btnLabel,
      ContentComponent: <MentorDrawerContent defaultValue={mentor?.info} {...mentor} />,
      onSuccess: mentor ? handleUpdate : handleCreate,
    })

    async function handleCreate({ imgFile, coordinatorImgFile, ...info }) {
      firestore.addMentor({ info }).then((mentorId) => {
        drawer.close()
        if (imgFile) {
          firestore.uploadImg(`mentors/${mentorId}`, imgFile)
        }
        if (coordinatorImgFile) {
          firestore.uploadImg(`mentors/${mentorId}/coordinator`, coordinatorImgFile)
        }
      })
    }

    async function handleUpdate({ imgFile, coordinatorImgFile, ...info }) {
      firestore.updateMentor(mentor.id, { info }).then(() => {
        drawer.close()
        if (imgFile) {
          firestore.uploadImg(`mentors/${mentor.id}`, imgFile)
        }
        if (coordinatorImgFile) {
          firestore.uploadImg(`mentors/${mentor.id}/coordinator`, coordinatorImgFile)
        }
      })
    }
  }

  async function handleDelete(mentor) {
    alertDialog.open({
      title: `メンター「${mentor.info?.name}」を削除しますか？`,
      cancelable: true,
      actions: [
        { label: '削除する', color: 'error', onClick: () => firestore.deleteMentor(mentor.id) },
      ],
    })
  }

  return (
    <Stack spacing={2} py={2} maxWidth='xl'>
      <Button
        variant='contained'
        color='primary'
        onClick={() => handleOpenDrawer()}
        sx={{ alignSelf: 'center' }}
      >
        メンターを登録
      </Button>

      <TableContainer variant='outlined' component={Paper} sx={{ width: '100%' }}>
        <Table>
          <TableHead sx={{ '& th': { fontSize: 'small', color: 'gray', padding: '8px 16px' } }}>
            <TableRow>
              <TableCell>企業名／氏名</TableCell>
              <TableCell>担当者名</TableCell>
              <TableCell>カテゴリ</TableCell>
              <TableCell>登録日時</TableCell>
              <TableCell sx={{ width: 0 }} />
            </TableRow>
          </TableHead>
          <TableBody>
            {mentorList.map((mentor) => {
              const { info = {} } = mentor
              const isOrganization = info.type === 'organization'
              return (
                <TableRow
                  key={mentor.id}
                  onClick={() => navigate(`./${mentor.id}`)}
                  sx={{ cursor: 'pointer', '&:hover': { bgcolor: 'background.focused' } }}
                >
                  <TableCell>
                    <Stack direction='row' spacing={1} alignItems='center'>
                      <Avatar
                        src={mentor.imgURL}
                        variant={isOrganization ? 'rounded' : 'circular'}
                        children={isOrganization ? <ApartmentIcon /> : undefined}
                        sx={{
                          width: 28,
                          height: 28,
                          border: '1px solid',
                          borderColor: 'border.sub',
                        }}
                      />
                      <Typography fontWeight='bold'>{info.name}</Typography>
                    </Stack>
                  </TableCell>
                  <TableCell>
                    {isOrganization ? (
                      <Stack direction='row' spacing={1} alignItems='center'>
                        <Avatar
                          src={mentor.coordinatorImgURL}
                          sx={{
                            width: 28,
                            height: 28,
                            border: '0.5px solid',
                            borderColor: 'border.sub',
                          }}
                        />
                        <Typography>{info.coordinatorName}</Typography>
                      </Stack>
                    ) : (
                      'ー'
                    )}
                  </TableCell>
                  <TableCell>
                    <Stack direction='row' spacing={0.5}>
                      {info.categoryIdArr?.map?.((id) => (
                        <Chip
                          key={id}
                          label={categoryList.find((x) => x.id === id)?.label}
                          variant='outlined'
                        />
                      ))}
                    </Stack>
                  </TableCell>
                  <TableCell>{time.toString(mentor.createdAt)}</TableCell>
                  <TableCell>
                    <MenuButton
                      items={[
                        {
                          label: '編集',
                          Icon: EditIcon,
                          color: 'primary',
                          onClick: () => handleOpenDrawer(mentor),
                        },
                        {
                          label: '削除',
                          Icon: DeleteForeverIcon,
                          color: 'error',
                          onClick: () => handleDelete(mentor),
                        },
                      ]}
                    />
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
  )
}
