import React from 'react'
import {
  Alert,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Snackbar,
} from '@mui/material'
import { FormContainer } from 'react-hook-form-mui'

export default (props) => {
  const {
    isOpen,
    onClose,
    operation,
    description,
    actions = [{ label: 'OK', autoFocus: true }],
    cancelable = false,
    isLoading = false,
    disableClose = isLoading,
    disableOptionalClose,
    maxWidth = 'sm',
    fullWidth = !isLoading,
    sx = {},
    children,
    Content,
    actionComponent,
    isSnackbar = false,
    isError = false,
    severity = isError ? 'error' : 'success',
    onSuccess,
    title = getDefaultTitle(),
    ...otherProps
  } = props

  function getDefaultTitle() {
    if (!operation) {
      return undefined
    }
    if (isSnackbar) {
      if (isError) {
        return `${operation}できませんでした`
      }
      return `${operation}しました`
    }
    if (isLoading) {
      return `${operation}しています・・・`
    }
    if (actions) {
      return `${operation}しますか？`
    }
    return undefined
  }

  function handleClose(_, reason) {
    if (disableClose) {
      return
    }
    if ((disableOptionalClose || isSnackbar) && reason && reason !== 'timeout') {
      return
    }
    onClose()
  }

  if (isSnackbar) {
    return (
      <Snackbar open={isOpen} onClose={handleClose} autoHideDuration={5000}>
        <Alert onClose={handleClose} severity={severity} variant='filled' sx={{ width: '100%' }}>
          {title}
        </Alert>
      </Snackbar>
    )
  }

  const Container = onSuccess ? FormContainer : React.Fragment
  const containerProps = onSuccess ? { onSuccess, fullWidth: true } : {}

  const allActions = actions ? [...actions] : null
  if (cancelable) {
    allActions.unshift({ label: 'キャンセル' })
  }

  if (!title) {
    sx.backgroundColor = 'transparent'
    sx.boxShadow = 'none'
  }

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      sx={{ zIndex: isLoading ? 9999 : undefined }}
      PaperProps={{ sx }}
      {...{ maxWidth, fullWidth }}
      keepMounted
      {...otherProps}
    >
      <Container {...containerProps}>
        {children || (
          <>
            {!!title && <DialogTitle>{title}</DialogTitle>}
            {isLoading ? (
              <CircularProgress sx={{ alignSelf: 'center', my: 2 }} />
            ) : (
              <>
                <DialogContent>
                  {Content || (
                    <DialogContentText sx={{ whiteSpace: 'pre-line' }}>
                      {description}
                    </DialogContentText>
                  )}
                </DialogContent>
                {(!!actionComponent || !!actions) && (
                  <DialogActions>
                    {actionComponent ||
                      allActions.map(({ onClick, label, ...otherProps }) => (
                        <Button
                          key={label}
                          onClick={() => {
                            if (onClick) {
                              onClick()
                            }
                            handleClose()
                          }}
                          {...otherProps}
                        >
                          {label}
                        </Button>
                      ))}
                  </DialogActions>
                )}
              </>
            )}
          </>
        )}
      </Container>
    </Dialog>
  )
}
