import React, { useEffect } from 'react'
import { TextFieldElement, RadioButtonGroup, useFormContext } from 'react-hook-form-mui'
import { Stack } from '@mui/material'

import ja from '../utils/ja.json'

export default (props) => {
  const { defaultValue } = props
  const { watch, reset, setValue, setError, clearErrors } = useFormContext()

  useEffect(() => {
    if (defaultValue) {
      reset(defaultValue)
    }
  }, [])

  return (
    <Stack spacing={4} p={4}>
      <TextFieldElement name='name' label='学校名' required fullWidth autoFocus />
      <RadioButtonGroup
        name='stage'
        label='種別'
        options={['juniorHigh', 'seniorHigh', 'juniorSeniorHigh'].map((id) => ({
          id,
          label: ja[id],
        }))}
        required
        row
      />
      <RadioButtonGroup
        name='type'
        label='区分'
        options={['private', 'public', 'national'].map((id) => ({ id, label: ja[id] }))}
        required
        row
      />
      <TextFieldElement name='url' label='URL' type='url' fullWidth />
    </Stack>
  )
}
