import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Typography, Container, Stack } from '@mui/material'
import { FormContainer } from 'react-hook-form-mui'
import { Swiper, SwiperSlide } from 'swiper/react'
import clsx from 'clsx'
import 'swiper/css'

import Firebase from '../containers/Firebase'
import AlertDialog from '../containers/AlertDialog'

import SignInFormContent from '../components/SignInFormContent'
import SwiperTabs from '../elements/SwiperTabs'
import SwiperTab from '../elements/SwiperTab'
import Card from '../elements/Card'

import logo from '../assets/logo.png'

export default () => {
  const navigate = useNavigate()
  const firebase = Firebase.useContainer()
  const alertDialog = AlertDialog.useContainer()

  const [swiper, setSwiper] = useState(null)
  const [tabIndex, setTabIndex] = useState(0)

  function handleTabChange(_, i) {
    setTabIndex(i)
    swiper.slideTo(i)
  }

  function isSameDomain(url) {
    const currentDomain = window.location.hostname
    try {
      const urlDomain = new URL(url).hostname
      return currentDomain === urlDomain
    } catch (error) {
      console.error('Invalid URL:', error)
      return false
    }
  }

  function onSubmit({ teamId, ...info }) {
    if (teamId) {
      info.email = `${teamId}@lab.e-donuts.com`
    }
    firebase
      .signIn(info)
      .then(() => {
        if (document.referrer && isSameDomain(document.referrer)) {
          navigate(-1)
        } else {
          // 自身のドメインでない場合やリファラーがない場合の処理
          navigate('/')
        }
      })
      .catch(() => {
        alertDialog.open({
          title: 'ログインできませんでした',
          description: `${
            teamId ? 'チームID' : 'メールアドレス'
          }とパスワードが正しく入力されているか確認してください。`,
        })
      })
  }

  function renderSlide(props) {
    return (
      <SwiperSlide>
        <FormContainer onSuccess={onSubmit} fullWidth>
          <SignInFormContent {...props} />
        </FormContainer>
      </SwiperSlide>
    )
  }

  return (
    <Container maxWidth='sm' sx={{ py: 4 }}>
      <Card p={4} sx={{ width: '100%' }}>
        <Stack spacing={4}>
          <Stack spacing={2} alignItems='center'>
            <img
              src={logo}
              alt='Donuts-Lab'
              style={{ width: '96%', maxWidth: '360px', aspectRatio: 5.2 }}
            />
            <Typography variant='body1'>ログインが必要です！</Typography>
          </Stack>

          <SwiperTabs value={tabIndex} onChange={handleTabChange} centered textColor='primary'>
            {['生徒', '教員'].map((label, i) => (
              <SwiperTab
                key={i}
                value={i}
                label={`${label}の方`}
                className={clsx({ focused: i === tabIndex })}
              />
            ))}
          </SwiperTabs>

          <Swiper
            simulateTouch={false}
            slidesPerView={1}
            spaceBetween={30}
            onSlideChange={({ activeIndex }) => setTabIndex(activeIndex)}
            onSwiper={setSwiper}
          >
            {renderSlide({ isStudent: true })}
            {renderSlide({ isStudent: false })}
          </Swiper>
        </Stack>
      </Card>
    </Container>
  )
}
