import React from 'react'
import { Stack, Typography, List, ListItem, ListItemText } from '@mui/material'
import Firestore from '../containers/Firestore'
import FirestoreSchool from '../containers/FirestoreSchool'
import Card from '../elements/Card'
import * as time from '../utils/time'
import supportTypeList from '../utils/supportTypeList.json'
import meetingFormatList from '../utils/meetingFormatList.json'

export default () => {
  const firestore = Firestore.useContainer()
  const { dataList: mentorList = [] } = firestore.useMentorCol()
  const firestoreSchool = FirestoreSchool.useContainer()
  const { dataList: applicationList = [] } = firestoreSchool.useMyApplicationCol()

  // 支援の種類をIDから日本語に変換
  const getSupportTypeLabel = (id) => {
    const type = supportTypeList.find((item) => item.id === id)
    return type ? type.label : id
  }

  // 希望形態をIDから日本語に変換
  const getMeetingFormatLabel = (id) => {
    const format = meetingFormatList.find((item) => item.id === id)
    return format ? format.label : id
  }

  return (
    <Stack spacing={3} py={2} maxWidth='md'>
      {applicationList?.map((application) => (
        <Card key={application.id}>
          <Stack spacing={3}>
            {/* メンター情報 */}
            {!!application.mentorId && (
              <Stack spacing={2}>
                <Typography variant='body1' fontWeight='bold'>
                  メンター
                </Typography>
                <Typography variant='body2'>
                  {mentorList.find((x) => x.id === application.mentorId)?.info?.name}
                </Typography>
              </Stack>
            )}

            {/* 相談内容 | 探究テーマ */}
            <Stack spacing={2}>
              <Typography variant='body1' fontWeight='bold'>
                相談したい内容 | 探究テーマ
              </Typography>
              <Typography variant='body2'>
                {/* NOTE: descriptionは旧バージョンのフォーム用 */}
                {application.info?.researchTheme || application.info?.description}
              </Typography>
            </Stack>

            {/* 希望する支援の種類（新バージョン用） */}
            {application.info?.supportTypes && (
              <Stack spacing={2}>
                <Typography variant='body1' fontWeight='bold'>
                  希望する支援の種類
                </Typography>
                <List disablePadding>
                  {application.info?.supportTypes.map((supportType, index) => (
                    <ListItem key={index} disableGutters>
                      <ListItemText
                        primary={getSupportTypeLabel(supportType)}
                        primaryTypographyProps={{ variant: 'body2' }}
                      />
                    </ListItem>
                  ))}
                </List>
              </Stack>
            )}

            {/* 支援内容の詳細（新バージョン用） */}
            {application.info?.supportDetails && (
              <Stack spacing={2}>
                <Typography variant='body1' fontWeight='bold'>
                  支援内容の詳細
                </Typography>
                <Typography variant='body2'>{application.info?.supportDetails}</Typography>
              </Stack>
            )}

            {/* 希望日時と形態（Q2の内容） */}
            {application.info?.meetingFormat && (
              <Stack spacing={2}>
                <Typography variant='body1' fontWeight='bold'>
                  希望日時と形態
                </Typography>
                <Typography variant='body2'>
                  {getMeetingFormatLabel(application.info?.meetingFormat)}
                </Typography>
                <List disablePadding>
                  {application.info?.availableSlots?.map((slot, index) => (
                    <ListItem key={index} disableGutters>
                      <ListItemText
                        primary={`第${index + 1}希望: ${time.formatDateTimeRange(slot)}`}
                        primaryTypographyProps={{ variant: 'body2' }}
                      />
                    </ListItem>
                  ))}
                </List>
              </Stack>
            )}

            {/* その他・補足事項 */}
            <Stack spacing={2}>
              <Typography variant='body1' fontWeight='bold'>
                その他・補足事項
              </Typography>
              <Typography variant='body2'>{application.info?.note || '-'}</Typography>
            </Stack>

            {/* 提出日時 */}
            <Stack spacing={2}>
              <Typography variant='body1' fontWeight='bold'>
                提出日時
              </Typography>
              <Typography variant='body2'>{time.toString(application.createdAt)}</Typography>
            </Stack>
          </Stack>
        </Card>
      ))}
    </Stack>
  )
}
