import React, { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Stack,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import VpnKeyIcon from '@mui/icons-material/VpnKey'

import Drawer from '../containers/Drawer'
import FirestoreSchool from '../containers/FirestoreSchool'

// import UserDrawerContent from "../components/UserDrawerContent";

import MenuButton from '../elements/MenuButton'
import * as time from '../utils/time'
import ja from '../utils/ja.json'
import StudentResetPasswordDrawerContent from '../components/StudentResetPasswordDrawerContent'
import Firebase from '../containers/Firebase'

export default () => {
  const navigate = useNavigate()
  const drawer = Drawer.useContainer()
  const firestoreSchool = FirestoreSchool.useContainer()
  const { docData: school = {} } = firestoreSchool
  const { dataList: userList = [] } = firestoreSchool.useUserCol()

  const [teacherUserList, studentUserList] = useMemo(() => {
    const teacherList = [],
      studentList = []
    userList.forEach((user) => {
      const role = school.userRoleObj?.[user.id]
      let list
      switch (role) {
        case 'teacher':
          list = teacherList
          break
        case 'student':
          list = studentList
          break
        default:
          break
      }
      list.push({ ...user, role })
    })
    return [teacherList, studentList]
  }, [userList, school.userRoleObj])

  function handleResetPassword(user) {
    console.log(user.id)
    drawer.open({
      title: 'パスワードをリセット',
      btnLabel: 'リセット',
      ContentComponent: <StudentResetPasswordDrawerContent defaultValue={user.info} />,
      onSuccess: async (info) => {
        await firestoreSchool
          .updateUser(user.id, {
            info: {
              ...user.info,
              password: info.password,
            },
          })
          .then(() => {
            drawer.close()
          })
      },
    })
  }

  // function handleOpenDrawer(lab) {
  //   const btnLabel = lab ? "更新" : "登録";
  //   drawer.open({
  //     title: `アカウントを${btnLabel}`,
  //     btnLabel,
  //     ContentComponent: <UserDrawerContent defaultValue={lab?.info} />,
  //     onSuccess: lab ? handleUpdate : handleCreate,
  //   });

  //   async function handleCreate(info) {
  //     firestoreSchool.addUser({ info })
  //       .then((labId) => {
  //         drawer.close();
  //         navigate(`./${labId}#invitations`);
  //       });
  //   }

  //   async function handleUpdate(info) {
  //     firestoreSchool.updateUser(user.id, { info });
  //   }
  // }

  // async function handleDelete(userId) {
  //   firestoreSchool.deleteUser(userId);
  // }

  function renderTable({ role, list = [], items = [] }) {
    const isStudent = role === 'student'
    return (
      <Accordion
        sx={{ width: '100%', '&:before': { display: 'none' } }}
        disableGutters
        defaultExpanded
        variant='outlined'
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          // sx={{ "& .MuiAccordionSummary-content": { margin: 0 }, "& .MuiAccordionSummary-expandIconWrapper": { position: "absolute", right: "16px" } }}
        >
          {`${ja[role]}アカウント (${list.length})`}
        </AccordionSummary>
        <AccordionDetails sx={{ padding: '8px' }}>
          <TableContainer variant='outlined' component={Paper} sx={{ width: '100%' }}>
            <Table>
              <TableHead sx={{ '& th': { fontSize: 'small', color: 'gray', padding: '8px 16px' } }}>
                <TableRow>
                  <TableCell>{isStudent ? 'チーム名' : '氏名'}</TableCell>
                  <TableCell>{isStudent ? 'チームID' : 'メールアドレス'}</TableCell>
                  {isStudent && <TableCell>パスワード</TableCell>}
                  {/* <TableCell>権限</TableCell> */}
                  <TableCell sx={{ width: 0 }} />
                </TableRow>
              </TableHead>
              <TableBody>
                {list.map((user) => {
                  const { info = {} } = user
                  return (
                    <TableRow
                      key={user.id}
                      // onClick={() => navigate(`./users/${user.id}`)}
                      // sx={{ cursor: "pointer", "&:hover": { bgcolor: "background.focused" } }}
                    >
                      <TableCell>{info.displayName}</TableCell>
                      <TableCell>{isStudent ? info.teamId : info.email}</TableCell>
                      {isStudent && <TableCell>{info.password}</TableCell>}
                      {/* <TableCell>{ja[school.userRoleObj?.[user.id]]}</TableCell> */}
                      <TableCell>
                        {isStudent && (
                          <MenuButton
                            items={[
                              {
                                label: 'パスワードを変更',
                                Icon: VpnKeyIcon,
                                color: 'primary',
                                onClick: () => handleResetPassword(user),
                              },
                            ]}
                          />
                        )}
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </AccordionDetails>
      </Accordion>
    )
  }

  return (
    <Stack spacing={2} py={2} maxWidth='xl'>
      {renderTable({ role: 'teacher', list: teacherUserList })}
      {renderTable({ role: 'student', list: studentUserList })}
    </Stack>
  )
}
