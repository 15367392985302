import React from 'react'
import { useNavigate } from 'react-router-dom'
import {
  Stack,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'

import Firestore from '../containers/Firestore'

import * as time from '../utils/time'

export default () => {
  const navigate = useNavigate()
  const firestore = Firestore.useContainer()
  const { dataList: applicationList = [] } = firestore.useApplicationColGroup()
  const { dataList: schoolList = [] } = firestore.useSchoolCol()

  return (
    <Stack spacing={2} py={2} maxWidth='xl'>
      <TableContainer variant='outlined' component={Paper} sx={{ width: '100%' }}>
        <Table>
          <TableHead sx={{ '& th': { fontSize: 'small', color: 'gray', padding: '8px 16px' } }}>
            <TableRow>
              <TableCell>学校名</TableCell>
              <TableCell>相談したい内容｜探究テーマ</TableCell>
              <TableCell>提出日時</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {applicationList.map((application) => (
              <TableRow
                key={application.id}
                onClick={() => navigate(`./${application.parentId}-${application.id}`)}
                sx={{ cursor: 'pointer', '&:hover': { bgcolor: 'background.focused' } }}
              >
                <TableCell>
                  {schoolList.find((x) => application.parentId === x.id)?.info?.name}
                </TableCell>
                <TableCell
                  sx={{
                    maxWidth: '20rem',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    textWrap: 'nowrap',
                  }}
                >
                  {/* NOTE: descriptionは旧バージョンのフォーム用 */}
                  {application.info?.researchTheme || application.info?.description}
                </TableCell>
                <TableCell>{time.toString(application.createdAt)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
  )
}
