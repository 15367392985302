import { useParams, Navigate } from 'react-router-dom'

import Firestore from '../containers/Firestore'
import FirestoreMentor from '../containers/FirestoreMentor'
import FirestoreSchool from '../containers/FirestoreSchool'
import FirestoreLab from '../containers/FirestoreLab'
import FirestoreInvitation from '../containers/FirestoreInvitation'
import FirestoreApplication from '../containers/FirestoreApplication'

import Home from './Home'
import SignIn from './SignIn'
import MentorList from './MentorList'
import MentorDetail from './MentorDetail'
import MentorForm from './MentorForm'
import Application from './Application'
import ApplicationForm from './ApplicationForm'
import ApplicationHistory from './ApplicationHistory'
import InvitationDetail from './InvitationDetail'
import ManageHome from './ManageHome'
import ManageApplicationList from './ManageApplicationList'
import ManageUserList from './ManageUserList'
import ManageLabList from './ManageLabList'
import ManageLabDetail from './ManageLabDetail'
import ManageInvitationList from './ManageInvitationList'
import AdminHome from './AdminHome'
import AdminApplicationList from './AdminApplicationList'
import AdminApplicationDetail from './AdminApplicationDetail'
import AdminSchoolList from './AdminSchoolList'
import AdminSchoolDetail from './AdminSchoolDetail'
import AdminMentorList from './AdminMentorList'
import ResetPassword from './ResetPassword'

export default [
  {
    path: '/',
    title: 'ホーム',
    isPinned: true,
    minUserRole: 'student',
    element: <Home />,
  },
  {
    path: '/signin',
    title: 'ログイン',
    minUserRole: 'guest',
    color: 'info',
    backgroundType: 'primary',
    element: <SignIn />,
  },
  {
    path: '/reset-password',
    title: 'パスワードリセット',
    minUserRole: 'guest',
    color: 'info',
    backgroundType: 'primary',
    element: <ResetPassword />,
  },
  {
    path: '/invitations/:invitationCode',
    title: '招待',
    minUserRole: 'guest',
    color: 'info',
    backgroundType: 'primary',
    isWaveShown: true,
    Component: () => {
      const { invitationCode } = useParams()
      return (
        <FirestoreInvitation.Provider initialState={{ invitationCode }}>
          <InvitationDetail />
        </FirestoreInvitation.Provider>
      )
    },
  },
  {
    path: '/mentors',
    title: 'メンターを探す',
    isPinned: true,
    minUserRole: 'student',
    color: 'error',
    element: <MentorList />,
  },
  {
    path: '/mentors/:mentorId',
    title: 'メンターの詳細',
    minUserRole: 'student',
    color: 'error',
    Component: () => {
      const { mentorId } = useParams()
      return (
        <FirestoreMentor.Provider initialState={{ mentorId }}>
          <MentorDetail />
        </FirestoreMentor.Provider>
      )
    },
  },
  {
    path: '/mentors/:mentorId/form',
    title: '相談フォーム',
    minUserRole: 'student',
    maxUserRole: 'teacher',
    color: 'error',
    Component: () => {
      const { mentorId } = useParams()
      return (
        <MySchoolProvider>
          <FirestoreMentor.Provider initialState={{ mentorId }}>
            <MentorForm />
          </FirestoreMentor.Provider>
        </MySchoolProvider>
      )
    },
  },
  {
    path: '/application',
    title: '協力を依頼する',
    isPinned: true,
    minUserRole: 'student',
    maxUserRole: 'teacher',
    color: 'success',
    element: <Application />,
  },
  {
    path: '/application/form',
    title: '相談フォーム',
    minUserRole: 'student',
    maxUserRole: 'teacher',
    color: 'success',
    backgroundType: 'sub',
    isWaveShown: true,
    element: (
      <MySchoolProvider>
        <ApplicationForm />
      </MySchoolProvider>
    ),
  },
  {
    path: '/application/history',
    title: '提出済みの相談フォーム',
    minUserRole: 'student',
    maxUserRole: 'student',
    color: 'success',
    backgroundType: 'sub',
    isWaveShown: true,
    element: (
      <MySchoolProvider>
        <ApplicationHistory />
      </MySchoolProvider>
    ),
  },
  {
    path: '/manage',
    title: '管理画面',
    isPinned: true,
    minUserRole: 'teacher',
    maxUserRole: 'teacher',
    color: 'darkGrey',
    element: <ManageHome />,
  },
  {
    path: '/manage/applications',
    title: '提出済み相談フォームの確認',
    minUserRole: 'teacher',
    maxUserRole: 'teacher',
    color: 'darkGrey',
    element: (
      <MySchoolProvider>
        <ManageApplicationList />
      </MySchoolProvider>
    ),
  },
  {
    path: '/manage/applications/:applicationId',
    title: '提出済み相談フォームの詳細',
    minUserRole: 'teacher',
    maxUserRole: 'teacher',
    color: 'darkGrey',
    Component: () => {
      const { applicationId } = useParams()
      return (
        <MySchoolProvider>
          <FirestoreApplication.Provider initialState={{ applicationId }}>
            <AdminApplicationDetail />
          </FirestoreApplication.Provider>
        </MySchoolProvider>
      )
    },
  },
  {
    path: '/manage/users',
    title: 'アカウントの管理',
    minUserRole: 'teacher',
    maxUserRole: 'teacher',
    color: 'darkGrey',
    element: (
      <MySchoolProvider>
        <ManageUserList />
      </MySchoolProvider>
    ),
  },
  {
    path: '/manage/labs',
    title: 'ラボの管理／生徒の招待',
    minUserRole: 'teacher',
    maxUserRole: 'teacher',
    color: 'darkGrey',
    element: (
      <MySchoolProvider>
        <ManageLabList />
      </MySchoolProvider>
    ),
  },
  {
    path: '/manage/labs/:labId',
    title: 'ラボの詳細',
    minUserRole: 'teacher',
    maxUserRole: 'teacher',
    color: 'darkGrey',
    Component: () => {
      const { labId } = useParams()
      return (
        <MySchoolProvider>
          <FirestoreLab.Provider initialState={{ labId }}>
            <ManageLabDetail />
          </FirestoreLab.Provider>
        </MySchoolProvider>
      )
    },
  },
  {
    path: '/manage/invitations',
    title: '教員の招待',
    minUserRole: 'teacher',
    maxUserRole: 'teacher',
    color: 'darkGrey',
    element: (
      <MySchoolProvider>
        <ManageInvitationList />
      </MySchoolProvider>
    ),
  },
  {
    path: '/admin',
    title: 'システム管理',
    isPinned: true,
    minUserRole: 'admin',
    color: 'darkGrey',
    element: <AdminHome />,
  },
  {
    path: '/admin/applications',
    title: '相談フォームリスト',
    minUserRole: 'admin',
    color: 'darkGrey',
    element: <AdminApplicationList />,
  },
  {
    path: '/admin/applications/:schoolIdAndApplicationId',
    title: '相談フォームの詳細',
    minUserRole: 'admin',
    color: 'darkGrey',
    Component: () => {
      const { schoolIdAndApplicationId } = useParams()
      const [schoolId, applicationId] = schoolIdAndApplicationId.split('-')
      return (
        <FirestoreSchool.Provider initialState={{ schoolId }}>
          <FirestoreApplication.Provider initialState={{ applicationId }}>
            <AdminApplicationDetail />
          </FirestoreApplication.Provider>
        </FirestoreSchool.Provider>
      )
    },
  },
  {
    path: '/admin/schools',
    title: '学校の管理',
    minUserRole: 'admin',
    color: 'darkGrey',
    element: <AdminSchoolList />,
  },
  {
    path: '/admin/schools/:schoolId',
    title: '学校の詳細／教員の招待',
    minUserRole: 'admin',
    color: 'darkGrey',
    Component: () => {
      const { schoolId } = useParams()
      return (
        <FirestoreSchool.Provider initialState={{ schoolId }}>
          <AdminSchoolDetail />
        </FirestoreSchool.Provider>
      )
    },
  },
  {
    path: '/admin/mentors',
    title: 'メンターの管理',
    minUserRole: 'admin',
    color: 'darkGrey',
    element: <AdminMentorList />,
  },
  {
    path: '/admin/mentors/:mentorId',
    title: 'メンターの詳細',
    minUserRole: 'admin',
    color: 'darkGrey',
    Component: () => {
      const { mentorId } = useParams()
      return (
        <FirestoreMentor.Provider initialState={{ mentorId }}>
          <MentorDetail />
        </FirestoreMentor.Provider>
      )
    },
  },
  {
    path: '/*',
    minUserRole: 'guest',
    element: <Navigate replace to='/' />,
  },
]

function MySchoolProvider(props) {
  const { mySchoolDocData } = Firestore.useContainer()
  if (!mySchoolDocData) {
    return null
  }
  return (
    <FirestoreSchool.Provider initialState={{ schoolId: mySchoolDocData.id }}>
      {props.children}
    </FirestoreSchool.Provider>
  )
}
