import React, { useEffect } from 'react'
import { Stack } from '@mui/material'
import { MuiFileInput } from 'mui-file-input'
import {
  TextFieldElement,
  RadioButtonGroup,
  useFormContext,
  TextareaAutosizeElement,
  AutocompleteElement,
} from 'react-hook-form-mui'
import AttachFileIcon from '@mui/icons-material/AttachFile'
import CloseIcon from '@mui/icons-material/Close'

import ja from '../utils/ja.json'
import categoryList from '../utils/categoryList.json'
import MentorCard from './MentorCard'

export default (props) => {
  const { defaultValue, imgURL, coordinatorImgURL } = props
  const { watch, reset, setValue } = useFormContext()

  const currentType = watch('type')
  const currentImgFile = watch('imgFile')
  const currentCoordinatorImgFile = watch('coordinatorImgFile')

  useEffect(() => {
    if (defaultValue) {
      reset(defaultValue)
    }
  }, [])

  return (
    <Stack spacing={4} p={4}>
      <MentorCard
        info={watch()}
        imgURL={currentImgFile ? URL.createObjectURL(currentImgFile) : imgURL}
        coordinatorImgURL={
          currentCoordinatorImgFile
            ? URL.createObjectURL(currentCoordinatorImgFile)
            : coordinatorImgURL
        }
      />
      <RadioButtonGroup
        name='type'
        label='区分'
        options={['organization', 'individual'].map((id) => ({ id, label: ja[id] }))}
        required
        row
      />
      <TextFieldElement
        name='name'
        label={currentType === 'organization' ? '企業名' : '氏名'}
        required
        fullWidth
      />
      <MuiFileInput
        label='アイコン画像'
        value={currentImgFile}
        onChange={(file) => setValue('imgFile', file)}
        InputProps={{ startAdornment: <AttachFileIcon /> }}
        clearIconButtonProps={{ children: <CloseIcon fontSize='small' /> }}
      />
      {currentType === 'organization' && (
        <Stack spacing={2}>
          <TextFieldElement name='coordinatorName' label='担当者名' required fullWidth />
          <MuiFileInput
            label='アイコン画像'
            value={currentCoordinatorImgFile}
            onChange={(file) => setValue('coordinatorImgFile', file)}
            InputProps={{ startAdornment: <AttachFileIcon /> }}
            clearIconButtonProps={{ children: <CloseIcon fontSize='small' /> }}
          />
        </Stack>
      )}
      <AutocompleteElement
        name='categoryIdArr'
        label='カテゴリ'
        options={categoryList}
        matchId
        multiple
        required
        showCheckbox
      />
      <TextareaAutosizeElement name='description' label='概要' required fullWidth rows={3} />
      <TextareaAutosizeElement name='benefit' label='支援できること' required fullWidth rows={3} />
      <TextFieldElement name='url' label='URL' type='url' fullWidth />
    </Stack>
  )
}
