import React, { useState, useEffect } from 'react'
import { Container, Stack, Typography } from '@mui/material'
import { FormContainer, TextFieldElement } from 'react-hook-form-mui'
import 'swiper/css'

import Firebase from '../containers/Firebase'
import AlertDialog from '../containers/AlertDialog'

import Card from '../elements/Card'

import logo from '../assets/logo.png'
import SubmitButton from '../elements/SubmitButton'

export default () => {
  const firebase = Firebase.useContainer()
  const alertDialog = AlertDialog.useContainer()
  const [isButtonDisabled, setIsButtonDisabled] = useState(false)
  const [countdown, setCountdown] = useState(30)

  useEffect(() => {
    let timer
    if (isButtonDisabled && countdown > 0) {
      timer = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1)
      }, 1000)
    } else if (countdown === 0) {
      setIsButtonDisabled(false)
      setCountdown(30)
    }
    return () => clearInterval(timer)
  }, [isButtonDisabled, countdown])

  function onSubmit({ ...info }) {
    setIsButtonDisabled(true)
    firebase
      .resetPasswordEmail(info)
      .then(() => {
        alertDialog.open({
          title: '入力したメールアドレスにメールを送信しました',
          description: `メールアプリを開いて、記載されているリンクをクリックしてください。`,
        })
      })
      .catch(() => {
        alertDialog.open({
          title: 'パスワードのリセットに失敗しました',
          description: `時間をおいて再度お試しください。`,
        })
        setIsButtonDisabled(false) // エラー時はすぐに再試行可能にする
        setCountdown(30)
      })
  }

  return (
    <Container maxWidth='sm' sx={{ py: 4 }}>
      <Card p={4} sx={{ width: '100%' }}>
        <Stack spacing={2}>
          <Stack spacing={2} alignItems='center'>
            <img
              src={logo}
              alt='Donuts-Lab'
              style={{ width: '96%', maxWidth: '360px', aspectRatio: 5.2 }}
            />
            <Typography variant='h5'>パスワードリセット</Typography>
            <Typography variant='body1'>
              パスワードをリセットするメールアドレスを入力してください
            </Typography>
          </Stack>

          <FormContainer onSuccess={onSubmit} fullWidth>
            <Stack spacing={4} py={2} alignItems='center'>
              <TextFieldElement
                name='email'
                label='メールアドレス'
                type='email'
                required
                fullWidth
              />
              <SubmitButton
                label={
                  isButtonDisabled ? `再送できるまであと ${countdown} 秒` : 'リセットメールを送信'
                }
                sx={{ width: 'fit-content' }}
                disabled={isButtonDisabled}
              />
            </Stack>
          </FormContainer>
        </Stack>
      </Card>
    </Container>
  )
}
