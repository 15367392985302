import React, { useCallback, useEffect } from 'react'
import { Button, Stack } from '@mui/material'

import Header from '../containers/Header'
import AlertDialog from '../containers/AlertDialog'
import Drawer from '../containers/Drawer'
import Snackbar from '../containers/Snackbar'
import Firestore from '../containers/Firestore'
import FirestoreLab from '../containers/FirestoreLab'

import InvitationDrawerContent from '../components/InvitationDrawerContent'

import InvitationListTable from '../components/InvitationListTable'

export default () => {
  const header = Header.useContainer()
  const alertDialog = AlertDialog.useContainer()
  const drawer = Drawer.useContainer()
  const snackbar = Snackbar.useContainer()
  const { mySchoolDocData = {} } = Firestore.useContainer()
  const firestoreLab = FirestoreLab.useContainer()
  const { dataList: invitationList = [] } = firestoreLab.useInvitationCol()

  const { docData: lab } = firestoreLab

  useEffect(() => {
    header.setAlert(lab.notFound ? { children: 'ラボが見つかりませんでした。' } : null)
    return () => header.setAlert(null)
  }, [lab.notFound])

  const getInvitationUrl = useCallback(
    (invitationId) =>
      `https://lab.e-donuts.com/invitations/${invitationId}-${mySchoolDocData?.id}-${lab.id}`,
    [mySchoolDocData?.id, lab.id],
  )

  function handleOpenDrawer(invitation) {
    const defaultValue = invitation
      ? { ...invitation.info, expiresAt: invitation.info.expiresAt?.toDate?.() }
      : undefined
    const btnLabel = defaultValue ? '更新' : '作成'
    drawer.open({
      title: `生徒用の招待リンクを${btnLabel}`,
      btnLabel,
      ContentComponent: <InvitationDrawerContent {...{ defaultValue }} />,
      onSuccess: defaultValue ? handleUpdate : handleCreate,
    })

    async function handleCreate(info) {
      firestoreLab
        .addInvitation({ info, schoolName: mySchoolDocData?.info?.name, labName: lab.info?.name })
        .then(onWrite)
    }

    async function handleUpdate(info) {
      firestoreLab.updateInvitation(invitation.id, { info }).then(() => onWrite(invitation.id))
    }

    function onWrite(invitationId) {
      drawer.close()
      navigator.clipboard.writeText(getInvitationUrl(invitationId))
      snackbar.open({ operation: '招待リンクをコピー' })
    }
  }

  async function handleDelete(invitation) {
    alertDialog.open({
      title: '招待リンクを削除しますか？',
      description: 'このリンクは無効になります。',
      cancelable: true,
      actions: [
        {
          label: '削除する',
          color: 'error',
          onClick: () => firestoreLab.deleteInvitation(invitation.id),
        },
      ],
    })
  }

  return (
    <Stack spacing={2} py={2} maxWidth='xl'>
      <Button
        variant='contained'
        color='primary'
        onClick={() => handleOpenDrawer()}
        sx={{ alignSelf: 'center' }}
      >
        生徒を招待する
      </Button>

      <InvitationListTable
        {...{ invitationList, getInvitationUrl }}
        onEditClick={handleOpenDrawer}
        onDeleteClick={handleDelete}
      />
    </Stack>
  )
}
