import React, { useEffect } from 'react'
import { TextFieldElement, useFormContext } from 'react-hook-form-mui'
import { Stack } from '@mui/material'

export default (props) => {
  const { defaultValue } = props
  const { watch, reset, setValue, setError, clearErrors } = useFormContext()

  useEffect(() => {
    if (defaultValue) {
      reset(defaultValue)
    }
  }, [])

  return (
    <Stack spacing={4} p={4}>
      <TextFieldElement name='password' label='パスワード' required fullWidth autoFocus />
    </Stack>
  )
}
