import React from 'react'
import { useNavigate } from 'react-router-dom'
import {
  Button,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'

import AlertDialog from '../containers/AlertDialog'
import Drawer from '../containers/Drawer'
import Firestore from '../containers/Firestore'

import SchoolDrawerContent from '../components/SchoolDrawerContent'

import MenuButton from '../elements/MenuButton'
import * as time from '../utils/time'
import ja from '../utils/ja.json'

export default () => {
  const navigate = useNavigate()
  const alertDialog = AlertDialog.useContainer()
  const drawer = Drawer.useContainer()
  const firestore = Firestore.useContainer()
  const { dataList: schoolList = [] } = firestore.useSchoolCol()

  function handleOpenDrawer(school) {
    const btnLabel = school ? '更新' : '登録'
    drawer.open({
      title: `学校を${btnLabel}`,
      btnLabel,
      ContentComponent: <SchoolDrawerContent defaultValue={school?.info} />,
      onSuccess: school ? handleUpdate : handleCreate,
    })

    async function handleCreate(info) {
      firestore.addSchool({ info }).then((schoolId) => {
        drawer.close()
        navigate(`./${schoolId}#invitations`)
      })
    }

    async function handleUpdate(info) {
      firestore.updateSchool(school.id, { info })
    }
  }

  async function handleDelete(school) {
    alertDialog.open({
      title: `学校「${school.info?.name}」を削除しますか？`,
      description: '所属する教員・生徒アカウントや提出済みの相談フォームもすべて削除されます。',
      cancelable: true,
      actions: [
        { label: '削除する', color: 'error', onClick: () => firestore.deleteSchool(school.id) },
      ],
    })
  }

  return (
    <Stack spacing={2} py={2} maxWidth='xl'>
      <Button
        variant='contained'
        color='primary'
        onClick={() => handleOpenDrawer()}
        sx={{ alignSelf: 'center' }}
      >
        学校を登録
      </Button>

      <TableContainer variant='outlined' component={Paper} sx={{ width: '100%' }}>
        <Table>
          <TableHead sx={{ '& th': { fontSize: 'small', color: 'gray', padding: '8px 16px' } }}>
            <TableRow>
              <TableCell>学校名</TableCell>
              <TableCell>種別</TableCell>
              <TableCell>区分</TableCell>
              <TableCell>登録日時</TableCell>
              <TableCell sx={{ width: 0 }} />
            </TableRow>
          </TableHead>
          <TableBody>
            {schoolList.map((school) => (
              <TableRow
                key={school.id}
                onClick={() => navigate(`./${school.id}`)}
                sx={{ cursor: 'pointer', '&:hover': { bgcolor: 'background.focused' } }}
              >
                <TableCell>{school.info?.name}</TableCell>
                <TableCell>{ja[school.info?.stage]}</TableCell>
                <TableCell>{ja[school.info?.type]}</TableCell>
                <TableCell>{time.toString(school.createdAt)}</TableCell>
                <TableCell>
                  <MenuButton
                    items={[
                      {
                        label: '編集',
                        Icon: EditIcon,
                        color: 'primary',
                        onClick: () => handleOpenDrawer(school),
                      },
                      {
                        label: '削除',
                        Icon: DeleteForeverIcon,
                        color: 'error',
                        onClick: () => handleDelete(school),
                      },
                    ]}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
  )
}
