import { styled } from '@mui/material/styles'
import Tabs from '@mui/material/Tabs'

export default styled(Tabs)(({ theme }) => ({
  width: '100%',
  minWidth: '212px',
  marginInline: 'auto',
  '& .MuiTabs-flexContainer': {
    backgroundColor: theme.palette.grey.main,
    display: 'inline-flex',
    borderRadius: '50px',
    padding: '7px 9px',
    width: '90%',
    marginInline: '5%',
    boxSizing: 'border-box',
  },
  '& .MuiTabs-indicator': {
    display: 'none',
  },
}))
