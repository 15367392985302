import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { ThemeProvider } from '@mui/material'

import App from './App'
import theme from './theme'
import './index.css'

import AlertDialog from './containers/AlertDialog'
import Drawer from './containers/Drawer'
import Header from './containers/Header'
import Snackbar from './containers/Snackbar'
import Firebase from './containers/Firebase'

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
  <BrowserRouter>
    <ThemeProvider {...{ theme }}>
      <AlertDialog.Provider>
        <Drawer.Provider>
          <Header.Provider>
            <Snackbar.Provider>
              <Firebase.Provider>
                <App />
              </Firebase.Provider>
            </Snackbar.Provider>
          </Header.Provider>
        </Drawer.Provider>
      </AlertDialog.Provider>
    </ThemeProvider>
  </BrowserRouter>,
)
