import { Alert, Button, Stack } from '@mui/material'
import { Link } from 'react-router-dom'

export default () => {
  return (
    <Alert
      severity='info'
      sx={{
        bgcolor: '#e3f2fd',
        border: '1px solid #90caf9',
        borderRadius: '8px',
        padding: '8px 16px',
        display: 'flex',
        alignItems: 'center',
        '& .MuiAlert-message': {
          width: '100%',
        },
      }}
    >
      <Stack
        direction='row'
        justifyContent='space-between'
        alignItems='center'
        spacing={2}
        sx={{ width: '100%' }}
      >
        <Stack direction='row' alignItems='center' spacing={2}>
          合致するメンターがいない場合は運営にざっくばらんに相談してみましょう
        </Stack>
        <Button
          color='primary'
          component={Link}
          variant='contained'
          to='/application/form'
          target='_blank'
        >
          協力を依頼する
        </Button>
      </Stack>
    </Alert>
  )
}
