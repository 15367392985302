import { createContainer } from 'unstated-next'
import { doc, collection } from 'firebase/firestore'

import Firestore from './Firestore'
import FirestoreSchool from './FirestoreSchool'

export default createContainer(({ labId }) => {
  const { useDocData, useColData, addDocData, updateDocData, deleteDocData } =
    Firestore.useContainer()
  const { labColRef } = FirestoreSchool.useContainer()

  const labDocRef = doc(labColRef, labId)
  const invitationColRef = collection(labDocRef, 'invitations')

  const { data: docData = {} } = useDocData(labDocRef)

  function update(data, operation) {
    return updateDocData(labDocRef, data, operation)
  }

  function useInvitationCol() {
    return useColData(invitationColRef)
  }

  async function addInvitation(data) {
    return addDocData(invitationColRef, data)
  }

  async function updateInvitation(invitationId, data) {
    return updateDocData(doc(invitationColRef, invitationId), data)
  }

  async function deleteInvitation(invitationId) {
    return deleteDocData(doc(invitationColRef, invitationId))
  }

  return {
    docData,
    update,
    useInvitationCol,
    addInvitation,
    updateInvitation,
    deleteInvitation,
  }
})
