import React from 'react'

import AppContainer from './containers/AppContainer'
import AlertDialog from './containers/AlertDialog'
import Drawer from './containers/Drawer'
import Snackbar from './containers/Snackbar'
import Firebase from './containers/Firebase'
import Firestore from './containers/Firestore'

export default () => {
  const alertDialog = AlertDialog.useContainer()
  const drawer = Drawer.useContainer()
  const snackbar = Snackbar.useContainer()
  const { currentUser } = Firebase.useContainer()

  return (
    <Firestore.Provider initialState={{ currentUser }}>
      <AppContainer.Provider>
        <AppContainerComponent />
        {alertDialog.Component}
        {drawer.Component}
        {snackbar.Component}
      </AppContainer.Provider>
    </Firestore.Provider>
  )
}

function AppContainerComponent() {
  const appContainer = AppContainer.useContainer()
  return appContainer.Component
}
