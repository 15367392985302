import React, { useEffect, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { createContainer } from 'unstated-next'
import { v4 } from 'uuid'

import Dialog from '../elements/Dialog'

export default createContainer(() => {
  const location = useLocation()
  const [items, setItems] = useState([])

  useEffect(() => {
    setItems([])
  }, [location])

  function open(item, id = v4()) {
    setTimeout(() => {
      setItems((prev) => {
        const index = prev.findIndex((item) => item.id === id)
        if (index > -1) {
          prev[index] = { id, ...item }
        } else {
          prev.push({ id, ...item })
        }
        return [...prev]
      })
    })
    return {
      isOpen: () => getIsOpen(id),
      close: () => close(id),
      update: (props) => update(id, props),
    }
  }

  function close(id) {
    setTimeout(() => {
      setItems((prev) => prev.filter((item) => item.id !== id))
    }, 300)
  }

  function update(id, props) {
    setItems((prev) => {
      const index = prev.findIndex((item) => item.id === id)
      if (index < 0) {
        return prev
      }
      prev[index] = { ...prev[index], ...props }
      return [...prev]
    })
  }

  function getIsOpen(id) {
    return items.some((item) => item.id === id)
  }

  const Component = useMemo(
    () => (
      <>
        {items
          .filter((item, i, arr) => !(item.isLoading && arr.findIndex((x) => x.isLoading) < i))
          .map(({ id, ...item }) => (
            <Dialog key={id} isOpen onClose={() => close(id)} {...item} sx={{ p: 1.5 }} />
          ))}
      </>
    ),
    [items],
  )

  return { open, close, update, getIsOpen, Component }
})
