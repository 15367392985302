import React from 'react'
import { useNavigate } from 'react-router-dom'
import {
  Chip,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
// import EditIcon from "@mui/icons-material/Edit";
// import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

// import AlertDialog from "../containers/AlertDialog";
// import Drawer from "../containers/Drawer";
import FirestoreSchool from '../containers/FirestoreSchool'

// import MenuButton from "../elements/MenuButton";
import * as time from '../utils/time'

export default () => {
  const navigate = useNavigate()
  // const alertDialog = AlertDialog.useContainer();
  // const drawer = Drawer.useContainer();
  const firestoreSchool = FirestoreSchool.useContainer()
  const { dataList: applicationList = [] } = firestoreSchool.useApplicationCol()
  const { dataList: userList = [] } = firestoreSchool.useUserCol()

  return (
    <Stack spacing={2} py={2} maxWidth='xl'>
      <TableContainer variant='outlined' component={Paper} sx={{ width: '100%' }}>
        <Table>
          <TableHead sx={{ '& th': { fontSize: 'small', color: 'gray', padding: '8px 16px' } }}>
            <TableRow>
              <TableCell>相談したい内容｜探究テーマ</TableCell>
              <TableCell>提出者</TableCell>
              <TableCell>提出日時</TableCell>
              {/* <TableCell sx={{ width: 0 }} /> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {applicationList.map((application) => (
              <TableRow
                key={application.id}
                onClick={() => navigate(`./${application.id}`)}
                sx={{ cursor: 'pointer', '&:hover': { bgcolor: 'background.focused' } }}
              >
                <TableCell
                  sx={{
                    maxWidth: '20rem',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    textWrap: 'nowrap',
                  }}
                >
                  {/* NOTE: descriptionは旧バージョンのフォーム用 */}
                  {application.info?.researchTheme || application.info?.description}
                </TableCell>
                <TableCell>
                  <Chip
                    label={
                      userList.find((x) => x.id === application.createdByUserId)?.info?.displayName
                    }
                    variant='outlined'
                  />
                </TableCell>
                <TableCell>{time.toString(application.createdAt)}</TableCell>
                {/* <TableCell>
                  <MenuButton
                    items={[
                      { label: "編集", Icon: EditIcon, color: "primary", onClick: () => handleOpenDrawer(application) },
                      { label: "削除", Icon: DeleteForeverIcon, color: "error", onClick: () => handleDelete(application) },
                    ]}
                  />
                </TableCell> */}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
  )
}
