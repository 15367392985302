import React, { useEffect } from 'react'
import { TextFieldElement, useFormContext } from 'react-hook-form-mui'
import { Link, Stack, Typography } from '@mui/material'

import PasswordInput from '../elements/PasswordInput'
import SubmitButton from '../elements/SubmitButton'

export default (props) => {
  const { defaultValue, isStudent } = props
  const { reset } = useFormContext()

  useEffect(() => {
    if (defaultValue) {
      reset(defaultValue)
    }
  }, [])

  return (
    <Stack spacing={4} py={2}>
      <Stack spacing={2} width='100%'>
        {isStudent ? (
          <TextFieldElement name='teamId' label='チームID' required fullWidth />
        ) : (
          <TextFieldElement name='email' label='メールアドレス' type='email' required fullWidth />
        )}

        <PasswordInput isSignIn />
      </Stack>

      <Stack spacing={2} alignItems='center'>
        <SubmitButton label='ログイン' sx={{ width: 'fit-content' }} />
        {!isStudent && (
          <Typography variant='body2'>
            パスワードをお忘れの方は<Link href='/reset-password'>こちら</Link>
          </Typography>
        )}
        {isStudent && (
          <Typography variant='body2'>
            ※チームIDまたはパスワードを忘れた場合は担当の先生に連絡してください
          </Typography>
        )}
      </Stack>
    </Stack>
  )
}
