import React from 'react'
import { IconButton } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import DeleteIcon from '@mui/icons-material/Delete'
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle'
import AddCircleIcon from '@mui/icons-material/AddCircle'

export default (props) => {
  const { type, color, size, ...restProps } = props

  let Icon, defaultColor
  switch (type) {
    case 'edit':
      Icon = EditIcon
      defaultColor = 'primary'
      break
    case 'duplicate':
      Icon = ContentCopyIcon
      break
    case 'delete':
      Icon = DeleteIcon
      break
    case 'remove':
      Icon = RemoveCircleIcon
      break
    case 'add':
      Icon = AddCircleIcon
      break
    default:
      break
  }

  return (
    <IconButton color={color || defaultColor} sx={{ padding: 0 }} {...restProps}>
      <Icon />
    </IconButton>
  )
}
