import React from 'react'
import { Stack, Typography, Chip, Avatar } from '@mui/material'
import ApartmentIcon from '@mui/icons-material/Apartment'
import PersonIcon from '@mui/icons-material/Person'

import Card from '../elements/Card'

import categoryList from '../utils/categoryList.json'

export default (props) => {
  const { info = {}, imgURL, coordinatorImgURL, sx } = props
  const isOrganization = info.type === 'organization'

  return (
    <Card {...{ sx }}>
      <Stack direction='row' spacing={2}>
        <Stack flex={1} spacing={1}>
          <Stack direction='row' spacing={1.5}>
            <Avatar
              src={imgURL}
              variant={isOrganization ? 'rounded' : 'circular'}
              children={isOrganization ? <ApartmentIcon fontSize='large' /> : undefined}
              sx={{ width: 56, height: 56, border: '1px solid', borderColor: 'border.sub' }}
            />
            <Stack>
              <Stack direction='row' spacing={0.5} alignItems='center'>
                {isOrganization ? <ApartmentIcon color='action' /> : <PersonIcon color='action' />}
                <Typography fontWeight='bold'>{info.name}</Typography>
              </Stack>
              {!isOrganization && info.belongsTo && (
                <Typography variant='caption'>所属： {info.belongsTo}</Typography>
              )}
              {isOrganization && info.coordinatorName && (
                <Stack direction='row' spacing={0.5} alignItems='center'>
                  <Typography variant='caption'>担当：</Typography>
                  <Avatar
                    src={coordinatorImgURL}
                    sx={{ width: 28, height: 28, border: '0.5px solid', borderColor: 'border.sub' }}
                  />
                  <Typography variant='caption'>{info.coordinatorName}</Typography>
                </Stack>
              )}
            </Stack>
          </Stack>
          <Stack direction='row' spacing={0.5}>
            {info.categoryIdArr?.map((id) => (
              <Chip
                key={id}
                label={categoryList.find((x) => x.id === id)?.label}
                color='error'
                // size="small"
                sx={{ borderRadius: 2 }}
              />
            ))}
          </Stack>
          <Typography
            variant='caption'
            lineHeight={1.8}
            sx={{
              overflow: 'hidden',
              display: '-webkit-box',
              WebkitBoxOrient: 'vertical',
              WebkitLineClamp: 3,
            }}
          >
            {info.description}
          </Typography>
        </Stack>
        <Stack flex={1}>
          <Card p={2} sx={{ height: '100%', backgroundColor: 'grey.main' }}>
            <Stack spacing={1}>
              <Typography variant='subtitle2'>支援できること</Typography>
              <Typography
                variant='caption'
                sx={{
                  overflow: 'hidden',
                  display: '-webkit-box',
                  WebkitBoxOrient: 'vertical',
                  WebkitLineClamp: 5,
                }}
              >
                {info.benefit}
              </Typography>
            </Stack>
          </Card>
        </Stack>
      </Stack>
    </Card>
  )
}
