import React, { useMemo } from 'react'
import { Link } from 'react-router-dom'
import { Button, Stack, Typography } from '@mui/material'

import Firestore from '../containers/Firestore'

import Card from '../elements/Card'
import Accordion from '../elements/Accordion'

export default () => {
  const { currentUserRole } = Firestore.useContainer()

  const historyTo = useMemo(() => {
    switch (currentUserRole) {
      case 'student':
        return './history'
      case 'teacher':
        return '/manage/applications'
      case 'admin':
        return '/admin/applications'
      default:
        return undefined
    }
  }, [currentUserRole])

  return (
    <Stack spacing={4} maxWidth='md'>
      <Stack spacing={2}>
        <Typography variant='h5' fontWeight='bold' color='white.main'>
          協力を依頼する
        </Typography>
        <Typography variant='caption' color='white.main'>
          登録メンター内に希望の相手がいない場合や、運営に相談したいことがある場合などはこちらから依頼してください。
        </Typography>
      </Stack>

      <Card>
        <Stack spacing={3}>
          <Stack spacing={1}>
            <Typography fontWeight='bold'>依頼の流れ</Typography>
            <Typography variant='caption'>
              1. 以下の依頼フォームに必要事項を記入し送信
              <br />
              2. 運営が内容を確認し、担当の先生にメールで連絡
              <br />
              3. 内容によって外部の紹介またはその他サポートを実施
            </Typography>
          </Stack>

          <Stack spacing={1}>
            <Typography fontWeight='bold'>実際の活用例</Typography>
            {accordionList.map(({ title, examples }, i) => (
              <Accordion
                key={i}
                title={title}
                titleVariant='body2'
                sx={{ p: 0, borderRadius: '8px!important' }}
              >
                <Stack>
                  {examples.map((example, j) => (
                    <Typography key={j} variant='caption'>
                      ・例：{example}
                    </Typography>
                  ))}
                </Stack>
              </Accordion>
            ))}
          </Stack>

          <Button
            component={Link}
            to='./form'
            target='_blank'
            variant='contained'
            sx={{ mx: 'auto', width: 'fit-content', alignSelf: 'center' }}
          >
            協力を依頼してみる
          </Button>
          <Button
            component={Link}
            to={historyTo}
            variant='outlined'
            sx={{ mx: 'auto', width: 'fit-content', alignSelf: 'center' }}
          >
            提出済みの依頼を確認する
          </Button>
        </Stack>
      </Card>
    </Stack>
  )
}

const accordionList = [
  {
    title: '相談・議論をする',
    examples: [
      '昆虫食の専門家と話をしたい',
      '環境問題に取り組んでいる方にインタビューをしたい',
      '地域のモノづくり企業の方の話を聞きたい',
    ],
  },
  {
    title: '自分たちのアイデアをプレゼンする',
    examples: [
      '自分たちの商品アイデアをメーカーにプレゼンして意見を聞きたい',
      '自分たちのアイデアをぶつけ、企業に共同企画・開発の打診をしたい',
      '地域課題を解決するアイデアを関係者に提案し、具体策を実行してみたい',
    ],
  },
  {
    title: '設備・備品を使いたい',
    examples: ['科学実験を行うために必要な設備を借りたい', 'モノづくりに必要な設備を借りたい'],
  },
  {
    title: 'その他',
    examples: [
      '〇〇の領域で活躍する方を学校にお呼びして講演をしてほしい',
      '探究で〇〇をしたいが、どのように進めればいいか相談したい',
    ],
  },
]
