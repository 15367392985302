import React, { useEffect, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { createContainer } from 'unstated-next'
import { Snackbar, Alert } from '@mui/material'

import { v4 } from 'uuid'

export default createContainer(() => {
  const location = useLocation()
  const [items, setItems] = useState([])

  useEffect(() => {
    setItems([])
  }, [location])

  function open(item, id = v4()) {
    setTimeout(() => {
      setItems((prev) => {
        const index = prev.findIndex((item) => item.id === id)
        if (index > -1) {
          prev[index] = { id, ...item }
        } else {
          prev.push({ id, ...item })
        }
        return [...prev]
      })
    })
    return {
      close: () => close(id),
    }
  }

  function close(id) {
    setTimeout(() => {
      setItems((prev) => prev.filter((item) => item.id !== id))
    }, 300)
  }

  const Component = useMemo(
    () => (
      <>
        {items.map(
          (
            { id, isError = false, severity = isError ? 'error' : 'success', title, operation },
            i,
          ) => {
            function handleClose(_, reason) {
              if (reason && reason !== 'timeout') {
                return
              }
              close(id)
            }

            return (
              <Snackbar
                key={id}
                open
                onClose={handleClose}
                autoHideDuration={5000}
                sx={{ bottom: `${24 + 56 * i}px !important` }}
              >
                <Alert
                  onClose={handleClose}
                  severity={severity}
                  variant='filled'
                  sx={{ width: '100%' }}
                >
                  {title || `${operation}${isError ? 'できませんでした' : 'しました'}`}
                </Alert>
              </Snackbar>
            )
          },
        )}
      </>
    ),
    [items],
  )

  return { open, close, Component }
})
