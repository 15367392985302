import React from 'react'
import { Typography, Accordion, AccordionSummary, AccordionDetails } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

export default (props) => {
  const { title, children, defaultExpanded, titleVariant, sx } = props
  return (
    <Accordion
      variant='outlined'
      sx={{
        backgroundColor: 'background.card',
        p: 1,
        border: '1px solid border.main',
        borderRadius: '16px !important',
        '&:before': { display: 'none' },
        ...sx,
      }}
      {...{ defaultExpanded }}
      disableGutters
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant={titleVariant} fontWeight='bold'>
          {title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  )
}
