import React from 'react'
import {
  Paper,
  Stack,
  Typography,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from '@mui/material'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { useNavigate } from 'react-router-dom'

export default () => {
  const navigate = useNavigate()

  return (
    <Stack spacing={2} maxWidth='md'>
      <Stack spacing={1}>
        <Typography variant='h5' fontWeight='bold' color='white.main'>
          システム管理画面
        </Typography>
      </Stack>

      <List component={Paper} variant='outlined'>
        {list.map((item, i) => (
          <ListItem key={i} disablePadding>
            <ListItemButton onClick={() => navigate(item.to)}>
              <ListItemText primary={item.title} />
              <ChevronRightIcon color='border' />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Stack>
  )
}

const list = [
  { title: '相談フォーム一覧', to: './applications' },
  { title: '学校を管理する／教員を招待する', to: './schools' },
  { title: 'メンターを管理する', to: './mentors' },
]
