import { createTheme } from '@mui/material'

export const palette = {
  primary: {
    main: '#0074CE',
    sub: '#8DB8E3',
    contrastText: '#FFFFFF',
  },
  info: {
    main: '#3283C0',
    contrastText: '#FFFFFF',
  },
  success: {
    main: '#6BA456',
    contrastText: '#FFFFFF',
  },
  error: {
    main: '#C0325D',
    contrastText: '#FFFFFF',
  },
  border: {
    main: '#B3B3B3',
    sub: '#E1DED5',
  },
  darkGrey: {
    main: '#666666',
    contrastText: '#FFFFFF',
  },
  grey: {
    main: '#EEEEEE',
  },
  white: {
    main: '#FFFFFF',
  },
  background: {
    main: '#FFFFFF',
    sub: '#F0F0F0',
    primary: '#8DB8E3',
    paper: '#ffffff',
    card: '#fcfdfd',
    default: '#f8f9fa',
    hovered: '#f3f4f5',
    focused: '#ecf2fc',
  },
  text: {
    main: '#4E4E4E',
  },
}

export default createTheme({
  palette,
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          minWidth: 0,
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          ':last-child': { paddingBottom: undefined },
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          width: 'fit-content',
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          '&.Mui-expanded:first-of-type': { marginTop: '16px' },
        },
      },
    },
    MuiTableBody: {
      styleOverrides: {
        root: {
          '& .MuiTableRow-root:last-of-type .MuiTableCell-root': {
            borderBottom: 'none',
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        label: {
          lineHeight: 'normal',
          fontWeight: 'normal',
        },
      },
    },
  },
  typography: {
    allVariants: {
      color: '#4E4E4E',
    },
    h5: {
      fontWeight: 'bold',
    },
    h6: {
      fontWeight: 'bold',
    },
    body1: {
      whiteSpace: 'pre-wrap',
    },
    body2: {
      whiteSpace: 'pre-wrap',
    },
    caption: {
      whiteSpace: 'pre-wrap',
    },
  },
})
