import { useEffect, useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router'
import { createContainer } from 'unstated-next'
import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'
import { useAuthState, useSignInWithEmailAndPassword, useSignOut, useSendPasswordResetEmail } from 'react-firebase-hooks/auth'
// import { Link, Stack, Typography } from "@mui/material";

import AlertDialog from './AlertDialog'

import config from '../env.json'

export default createContainer(() => {
  const location = useLocation()
  const navigate = useNavigate()
  const alertDialog = AlertDialog.useContainer()

  const app = initializeApp(config.firebase)
  const auth = getAuth(app)
  const [user, loading, error] = useAuthState(auth)
  const [signInWithEmailAndPassword] = useSignInWithEmailAndPassword(auth)
  const [sendPasswordResetEmail] = useSendPasswordResetEmail(auth)
  const [signOut] = useSignOut(auth)

  const currentUser = useMemo(() => {
    if (loading) {
      return JSON.parse(localStorage.getItem('user'))
    }
    if (error || !user) {
      return undefined
    }
    return {
      uid: user.uid,
      email: user.email,
      displayName: user.displayName,
      isAdmin: user.uid === 'QQAF494tEbZXodvDtt13gSb1xzb2',
      // photoURL: user.photoURL,
    }
  }, [user, loading, error])

  useEffect(() => {
    if (loading) {
      return
    }
    if (user) {
      localStorage.setItem('user', JSON.stringify(currentUser))
      if (alertDialog.getIsOpen('signIn')) {
        alertDialog.close('signIn')
      }
    } else if (!location.pathname.match(/^\/(signin|invitations|reset-password)/)) {
      signInByStorage()
    }
    // } else if ((!location.pathname.startsWith("/invitations")) && !alertDialog.getIsOpen("signIn")) {
    //   alertDialog.open({
    //     title: "ログインが必要です",
    //     disableClose: true,
    //     actions: null,
    //     // Content: (
    //     //   <Stack>
    //     //     <GoogleButton onClick={() => signIn()} style={{ margin: "20px auto" }} />
    //     //   </Stack>
    //     // ),
    //   }, "signIn");
    // }
  }, [user, loading, error, location.pathname])

  async function signIn(authSecret) {
    return new Promise((resolve, reject) => {
      signInWithEmailAndPassword(
        authSecret.email || `${authSecret.teamId}@lab.e-donuts.com`,
        authSecret.password,
      )
        .then((result) => {
          if (!result) {
            return reject()
          }
          localStorage.setItem('authSecret', JSON.stringify(authSecret))
          resolve(result)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  async function signInByStorage() {
    const authSecret = JSON.parse(localStorage.getItem('authSecret'))
    if (!authSecret) {
      navigate('/signin')
      return
    }
    signIn(authSecret)
      .then()
      .catch(() => navigate('/signin'))
  }

  // function showSignInDialog(isError = false) {
  //   alertDialog.open({
  //     title: isError ? "ログインに失敗しました" : "ログインが必要です",
  //     disableClose: true,
  //     actions: null,
  //     Content: <AuthSecretFormContent />,
  //   }, "signIn");
  // }

  async function resetPasswordEmail(input) {
    return new Promise((resolve, reject) => {
      sendPasswordResetEmail(input.email, {
        url: "http://lab.e-donuts.com/signin",
        handleCodeInApp: false,
      })
        .then((result) => {
          if (!result) {
            return reject()
          }
          resolve(result)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  return { currentUser, signIn, resetPasswordEmail }

  // // useEffect(() => {
  // //   localStorage.removeItem("token");
  // // }, []);

  // // useEffect(() => {
  // //   signInByStorage();
  // // }, []);

  // // function signInByStorage() {
  // //   const token = JSON.parse(localStorage.getItem("token"));
  // //   if (!token) { return; }
  // //   const credential = GoogleAuthProvider.credential(null, token);
  // //   signInWithCredential(auth, credential)
  // //     .then((result) => {
  // //       console.log("Sign In Success - credential", result);
  // //     })
  // //     .catch((error) => {
  // //       console.log("Sign In Error - credential", error);
  // //     });
  // // }

  // const [user, loading, error] = useAuthState(auth);

  // const currentUser = useMemo(() => {
  //   if (loading) {
  //     return JSON.parse(localStorage.getItem("user"));
  //   }
  //   if (error || !user) {
  //     return undefined;
  //   }
  //   return {
  //     uid: user.uid,
  //     email: user.email,
  //     displayName: user.displayName,
  //     photoURL: user.photoURL,
  //   };
  // }, [user, loading, error]);

  // return { currentUser, signIn, signOut };
})
