import React, { useEffect } from 'react'
import {
  TextFieldElement,
  useFormContext,
  TextareaAutosizeElement,
  AutocompleteElement,
} from 'react-hook-form-mui'
import { Stack } from '@mui/material'

export default (props) => {
  const { defaultValue, teacherUserList } = props
  const { reset } = useFormContext()

  useEffect(() => {
    if (defaultValue) {
      reset(defaultValue)
    }
  }, [])

  return (
    <Stack spacing={4} p={4}>
      <TextFieldElement name='name' label='ラボ名' required autoFocus />
      <TextareaAutosizeElement name='description' label='概要' autoFocus rows={3} />
      <AutocompleteElement
        name='teacherUserIdArr'
        label='担当教員'
        options={teacherUserList}
        matchId
        multiple
        autocompleteProps={{
          getOptionLabel: (x) => `${x.info?.displayName}（${x.info?.email}）`,
          noOptionsText: '該当する教員が登録されていません',
          disableClearable: true,
          disablePortal: true,
          fullWidth: true,
          componentsProps: {
            popper: {
              sx: { transition: 'none' },
            },
          },
        }}
      />
    </Stack>
  )
}
