import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Stack, Typography, Chip, Button, Divider, Avatar } from '@mui/material'
import ApartmentIcon from '@mui/icons-material/Apartment'
import PersonIcon from '@mui/icons-material/Person'

import Header from '../containers/Header'
import FirestoreMentor from '../containers/FirestoreMentor'

import categoryList from '../utils/categoryList.json'

export default () => {
  const header = Header.useContainer()
  const firestoreMentor = FirestoreMentor.useContainer()
  const { docData: mentor = {} } = firestoreMentor
  const { info = {} } = mentor

  useEffect(() => {
    header.setAlert(mentor.notFound ? { children: 'メンターが見つかりませんでした。' } : null)
    return () => {
      header.setAlert(null)
    }
  }, [mentor.notFound])

  // useEffect(() => {
  //   appContainer.setSegmentTitle(mentor.id, name);
  // }, [name]);

  if (!mentor.id) {
    return null
  }

  return (
    <Stack maxWidth='md'>
      <Stack direction='row' spacing={2} py={2}>
        <MentorDetailHeader {...mentor} />
        <Stack pt={2}>
          <Button component={Link} to='./form' target='_blank' variant='contained'>
            相談してみる
          </Button>
        </Stack>
      </Stack>

      <Divider sx={{ my: 1.5 }} />

      <Stack spacing={5} py={2}>
        <Stack spacing={1.5}>
          <Typography variant='h6'>
            {info.type === 'organization' ? '企業概要' : 'プロフィール'}
          </Typography>
          <Typography variant='body1' lineHeight={1.8}>
            {info.description}
          </Typography>
        </Stack>

        <Stack spacing={1.5}>
          <Typography variant='h6'>支援できること</Typography>
          <Typography variant='body1' lineHeight={1.8}>
            {info.benefit}
          </Typography>
        </Stack>

        <Stack spacing={1.5}>
          <Typography variant='h6'>相談してみる</Typography>
          <Stack spacing={2}>
            <Typography variant='body1' lineHeight={1.8}>
              <b>
                {info.name}
                {info.type === 'individual' ? 'さん' : ''}
              </b>{' '}
              に連絡して、実際に話を聞いてみましょう。
            </Typography>
            <Button
              component={Link}
              to='./form'
              target='_blank'
              variant='contained'
              sx={{ width: 'fit-content' }}
            >
              相談してみる
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  )
}

export function MentorDetailHeader(props) {
  const { info = {}, imgURL, coordinatorImgURL } = props
  const isOrganization = info.type === 'organization'

  return (
    <Stack flex={1} spacing={2}>
      <Stack direction='row' spacing={3} alignItems='center'>
        <Avatar
          src={imgURL}
          variant={isOrganization ? 'rounded' : 'circular'}
          children={isOrganization ? <ApartmentIcon fontSize='large' /> : undefined}
          sx={{ width: 96, height: 96, border: '1px solid', borderColor: 'border.sub' }}
        />
        <Stack spacing={1}>
          <Stack direction='row' spacing={1.5} alignItems='center'>
            {isOrganization ? <ApartmentIcon color='action' /> : <PersonIcon color='action' />}
            <Typography variant='h6'>{info.name}</Typography>
          </Stack>
          {!isOrganization && info.belongsTo && (
            <Typography variant='body2'>所属： {info.belongsTo}</Typography>
          )}
          {isOrganization && info.coordinatorName && (
            <Stack direction='row' spacing={1.5} alignItems='center'>
              <Typography variant='body1'>担当：</Typography>
              <Avatar
                src={coordinatorImgURL}
                sx={{ width: 48, height: 48, border: '0.5px solid', borderColor: 'border.sub' }}
              />
              <Typography variant='body1'>{info.coordinatorName}</Typography>
            </Stack>
          )}
        </Stack>
      </Stack>
      <Stack direction='row' spacing={1}>
        {info.categoryIdArr?.map((id) => (
          <Chip
            key={id}
            label={categoryList.find((x) => x.id === id)?.label}
            color='error'
            sx={{ borderRadius: 2 }}
          />
        ))}
      </Stack>
    </Stack>
  )
}
