import React, { useEffect, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { createContainer } from 'unstated-next'
import { FormContainer } from 'react-hook-form-mui'
import {
  Drawer,
  Box,
  Typography,
  Stack,
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

import SubmitButton from '../elements/SubmitButton'

export default createContainer(() => {
  const location = useLocation()
  const [isOpen, setIsOpen] = useState(false)
  const [item, setItem] = useState(null)
  const [prevItem, setPrevItem] = useState(null)

  useEffect(() => {
    close()
  }, [location])

  function open(item) {
    setItem(item)
    setPrevItem(item)
    setIsOpen(true)
  }

  function close() {
    setIsOpen(false)
    setTimeout(() => {
      setItem(null)
      setPrevItem(null)
    }, 300)
  }

  function onClose(_, reason) {
    if (!item.enableOptionalClose && reason) {
      return
    }
    close()
  }

  function update(props) {
    if (!item) {
      return
    }
    setItem((prev) => ({ ...prev, ...props }))
  }

  function revert() {
    setItem(prevItem)
  }

  const {
    ContentComponent,
    onSuccess,
    title,
    btnLabel,
    defaultValue,
    readOnly,
    LeftIcon = CloseIcon,
    onLeftIconClick = close,
  } = item || {}

  const Component = useMemo(
    () => (
      <Drawer open={isOpen} onClose={onClose} anchor='right'>
        <FormContainer
          onSuccess={!readOnly && onSuccess}
          FormProps={{
            onKeyDown: (e) => {
              if (e.key === 'Enter' && e.target.tagName !== 'TEXTAREA') {
                e.preventDefault()
              }
            },
          }}
        >
          <Box sx={{ width: 850 }}>
            <ListItem divider>
              <ListItemIcon>
                <IconButton onClick={onLeftIconClick}>
                  <LeftIcon />
                </IconButton>
              </ListItemIcon>
              <ListItemText
                primary={
                  <Stack direction='row'>
                    <Typography variant='h6'>{title}</Typography>
                  </Stack>
                }
              />
              {!!btnLabel && !readOnly && <SubmitButton label={btnLabel} {...{ defaultValue }} />}
            </ListItem>

            {ContentComponent}
          </Box>
        </FormContainer>
      </Drawer>
    ),
    [isOpen, item],
  )

  return { open, close, update, revert, Component }
})
