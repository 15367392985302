import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useLocation, Link } from 'react-router-dom'
import { createContainer } from 'unstated-next'
import { Tabs, Tab, Box, Alert } from '@mui/material'

export default createContainer(() => {
  const ref = useRef(null)
  const location = useLocation()
  const [height, setHeight] = useState(0)
  const [tabs, setTabs] = useState()
  const [alert, setAlert] = useState()

  const fragment = useMemo(() => location.hash.replace(/^#/, ''), [location.hash])

  useEffect(() => {
    setHeight(ref.current?.clientHeight)
  }, [tabs, alert])

  const Component = (
    <>
      <Box zIndex={100} ref={ref}>
        {!!tabs && (
          <Tabs
            value={fragment}
            sx={{ backgroundColor: 'white.main', borderBottom: 1, borderColor: 'divider' }}
          >
            {tabs.map(({ label, value }) => (
              <Tab label={label} key={value} value={value} to={'#' + value} component={Link} />
            ))}
          </Tabs>
        )}

        {!!alert && <Alert severity='error' {...alert} />}
      </Box>
      <Box height={height} />
    </>
  )

  return { Component, setTabs, setAlert, fragment }
})
