import React, { useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { Alert, Button, Stack, Typography } from '@mui/material'

import Firestore from '../containers/Firestore'

import SearchAccordion from '../components/SearchAccordion'
import MentorCard from '../components/MentorCard'
import NoMatchingMentorAlert from '../components/NoMatchingMentorAlert'

export default () => {
  const firestore = Firestore.useContainer()
  const { dataList: mentorList = [] } = firestore.useMentorCol()
  const [selectedCategoryIdArr, setSelectedCategoryIdArr] = useState([])

  const shownMentorList = useMemo(() => {
    if (!selectedCategoryIdArr.length) {
      return mentorList
    }
    return mentorList.filter((x) =>
      x.info?.categoryIdArr?.some?.((x) => selectedCategoryIdArr.includes(x)),
    )
  }, [mentorList, selectedCategoryIdArr])

  return (
    <Stack spacing={4} maxWidth='md'>
      <Stack spacing={2}>
        <Typography variant='h5' fontWeight='bold' color='white.main'>
          メンターを探す
        </Typography>
        <Typography variant='caption' color='white.main'>
          探究を外から支えてくれる企業や個人のメンターを探すことができます。
        </Typography>
      </Stack>

      <SearchAccordion onChange={setSelectedCategoryIdArr} />

      <NoMatchingMentorAlert />

      <Stack spacing={2}>
        {shownMentorList.map((mentor) => (
          <Link key={mentor.id} to={`./${mentor.id}`}>
            <MentorCard {...mentor} sx={{ '&:hover': { bgcolor: 'background.focused' } }} />
          </Link>
        ))}
      </Stack>
    </Stack>
  )
}
