import { createContainer } from 'unstated-next'
import { doc, collection, where, orderBy } from 'firebase/firestore'

import Firebase from './Firebase'
import Firestore from './Firestore'

export default createContainer(({ schoolId }) => {
  const { currentUser } = Firebase.useContainer()
  const { useDocData, useColData, addDocData, updateDocData, deleteDocData, schoolColRef } =
    Firestore.useContainer()

  const schoolDocRef = doc(schoolColRef, schoolId)
  const invitationColRef = collection(schoolDocRef, 'invitations')
  const labColRef = collection(schoolDocRef, 'labs')
  const userColRef = collection(schoolDocRef, 'users')
  const applicationColRef = collection(schoolDocRef, 'applications')

  const { data: docData = {} } = useDocData(schoolDocRef)

  function update(data, operation) {
    return updateDocData(schoolDocRef, data, operation)
  }

  function useUserCol() {
    return useColData(userColRef)
  }

  async function addUser(data) {
    return addDocData(userColRef, data, 'ユーザーを登録')
  }

  async function updateUser(userId, data) {
    return updateDocData(doc(userColRef, userId), data, 'ユーザーを更新')
  }

  async function deleteUser(userId) {
    return deleteDocData(doc(userColRef, userId), 'ユーザーを削除')
  }

  function useLabCol() {
    return useColData(labColRef)
  }

  async function addLab(data) {
    return addDocData(labColRef, data, 'ラボを登録')
  }

  async function updateLab(labId, data) {
    return updateDocData(doc(labColRef, labId), data, 'ラボを更新')
  }

  async function deleteLab(labId) {
    return deleteDocData(doc(labColRef, labId))
  }

  function useInvitationCol() {
    return useColData(invitationColRef)
  }

  async function addInvitation(data) {
    return addDocData(invitationColRef, data, '招待リンクを作成')
  }

  async function updateInvitation(invitationId, data) {
    return updateDocData(doc(invitationColRef, invitationId), data, '招待リンクを更新')
  }

  async function deleteInvitation(invitationId) {
    return deleteDocData(doc(invitationColRef, invitationId), '招待リンクを削除')
  }

  function useApplicationCol() {
    return useColData(applicationColRef)
  }

  function useMyApplicationCol() {
    return useColData(
      applicationColRef,
      where('createdByUserId', '==', currentUser.uid),
      orderBy('createdAt', 'desc'),
    )
  }

  async function addApplication(data) {
    return addDocData(applicationColRef, data, '相談フォームを送信')
  }

  async function updateApplication(applicationId, data) {
    return updateDocData(doc(applicationColRef, applicationId), data, '相談フォームを更新')
  }

  async function deleteApplication(applicationId) {
    return deleteDocData(doc(applicationColRef, applicationId))
  }

  return {
    docData,
    update,
    useUserCol,
    addUser,
    updateUser,
    deleteUser,
    userColRef,
    useLabCol,
    addLab,
    updateLab,
    deleteLab,
    labColRef,
    useInvitationCol,
    addInvitation,
    updateInvitation,
    deleteInvitation,
    invitationColRef,
    useApplicationCol,
    useMyApplicationCol,
    addApplication,
    updateApplication,
    deleteApplication,
    applicationColRef,
  }
})
