import React from 'react'
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'

import MenuButton from '../elements/MenuButton'
import * as time from '../utils/time'
import CopyButton from '../elements/CopyButton'

export default (props) => {
  const { invitationList, getInvitationUrl, onEditClick, onDeleteClick } = props

  return (
    <TableContainer variant='outlined' component={Paper} sx={{ width: '100%' }}>
      <Table>
        <TableHead sx={{ '& th': { fontSize: 'small', color: 'gray', padding: '8px 16px' } }}>
          <TableRow>
            <TableCell>アカウント数</TableCell>
            <TableCell>有効期限</TableCell>
            <TableCell>作成日時</TableCell>
            <TableCell>招待リンク</TableCell>
            <TableCell sx={{ width: 0 }} />
          </TableRow>
        </TableHead>
        <TableBody>
          {invitationList.map((item) => (
            <TableRow key={item.id}>
              <TableCell>{`${item.count || 0} / ${item.info?.maxCount || '∞'}`}</TableCell>
              <TableCell
                sx={{ color: time.isPast(item.info?.expiresAt) ? 'error.main' : 'inherit' }}
              >
                {time.toString(item.info?.expiresAt)}
              </TableCell>
              <TableCell>{time.toString(item.createdAt)}</TableCell>
              <TableCell>
                <CopyButton value={getInvitationUrl(item.id)} />
              </TableCell>
              <TableCell>
                <MenuButton
                  items={[
                    {
                      label: '編集',
                      Icon: EditIcon,
                      color: 'primary',
                      onClick: () => onEditClick(item),
                    },
                    {
                      label: '削除',
                      Icon: DeleteForeverIcon,
                      color: 'error',
                      onClick: () => onDeleteClick(item),
                    },
                  ]}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
