import { createContainer } from 'unstated-next'
import { doc } from 'firebase/firestore'

import Firestore from './Firestore'

export default createContainer(({ mentorId }) => {
  const { useDocData, updateDocData, mentorColRef } = Firestore.useContainer()

  const mentorDocRef = doc(mentorColRef, mentorId)
  const { data: docData = {} } = useDocData(mentorDocRef)

  function update(data, operation) {
    return updateDocData(mentorDocRef, data, operation)
  }

  return { docData, update }
})
