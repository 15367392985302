import React, { useEffect, useState } from 'react'
import { TextFieldElement, useFormContext } from 'react-hook-form-mui'
import { Stack } from '@mui/material'

import CalendarPicker from '../elements/CalendarPicker'
import ActionButton from '../elements/ActionButton'

export default (props) => {
  const { defaultValue = { maxCount: 10 } } = props
  const { watch, reset, setValue } = useFormContext()
  const [currentExpiresAt, setCurrentExpiresAt] = useState(defaultValue?.expiresAt)

  useEffect(() => {
    if (defaultValue) {
      reset(defaultValue)
    }
  }, [])

  useEffect(() => {
    const subscription = watch(({ expiresAt }) => {
      setCurrentExpiresAt(expiresAt)
    })
    return () => subscription.unsubscribe()
  }, [watch])

  return (
    <Stack spacing={4} p={4}>
      {/* <RadioButtonGroup
        name="role"
        label="権限"
        options={["student"].map((id) => ({ id, label: ja[id] }))}
        required
        row
      /> */}
      <Stack direction='row' alignItems='center' spacing={3}>
        <CalendarPicker
          label='有効期限'
          value={currentExpiresAt}
          onChange={(value) => setValue('expiresAt', value)}
        />
        {!!currentExpiresAt && (
          <ActionButton type='remove' onClick={() => setValue('expiresAt', null)} />
        )}
      </Stack>

      <TextFieldElement
        name='maxCount'
        label='上限アカウント数'
        sx={{ width: '30%', minWidth: 180 }}
        InputProps={{
          type: 'number',
        }}
      />
    </Stack>
  )
}
