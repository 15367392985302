import { useEffect, useRef, useState } from "react";

export const HERO_ID = "hero";

/**
 * 要素が指定された背景と重なっているかを検出
 */
export const useOverlapDetection = (backgroundId) => {
  const elementRef = useRef(null);
  const [isOverlapping, setIsOverlapping] = useState(false);

  const checkOverlap = () => {
    if (elementRef.current) {
      const rect = elementRef.current.getBoundingClientRect();
      const style = window.getComputedStyle(elementRef.current);
      const margin = {
        top: parseFloat(style.marginTop),
        right: parseFloat(style.marginRight),
        bottom: parseFloat(style.marginBottom),
        left: parseFloat(style.marginLeft)
      };

      const elementArea = {
        top: rect.top + margin.top,
        right: rect.right - margin.right,
        bottom: rect.bottom - margin.bottom,
        left: rect.left + margin.left
      };

      const backgroundElement = document.getElementById(backgroundId);
      if (backgroundElement) {
        const backgroundRect = backgroundElement.getBoundingClientRect();
        setIsOverlapping(
          elementArea.top < backgroundRect.bottom &&
          elementArea.bottom > backgroundRect.top &&
          elementArea.left < backgroundRect.right &&
          elementArea.right > backgroundRect.left
        );
      }
    }
  };

  useEffect(() => {
    checkOverlap();
    window.addEventListener('resize', checkOverlap);
    window.addEventListener('scroll', checkOverlap);
    return () => {
      window.removeEventListener('resize', checkOverlap);
      window.removeEventListener('scroll', checkOverlap);
    };
  }, [backgroundId]);

  return [elementRef, isOverlapping];
};
