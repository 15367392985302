import { useEffect, useMemo } from 'react'
import { createContainer as createUnstatedContainer } from 'unstated-next'
import {
  getFirestore,
  doc,
  collection,
  collectionGroup,
  addDoc,
  setDoc,
  updateDoc,
  deleteDoc,
  onSnapshot,
  serverTimestamp,
  orderBy,
  query,
  where,
} from 'firebase/firestore'
import { useDocument, useCollection } from 'react-firebase-hooks/firestore'
import { getStorage, ref, uploadBytes } from 'firebase/storage'

import AlertDialog from './AlertDialog'
import Snackbar from './Snackbar'

require('firebase/firestore')

export default createUnstatedContainer(({ currentUser }) => {
  const alertDialog = AlertDialog.useContainer()
  const snackbar = Snackbar.useContainer()
  const { uid = null, isAdmin } = currentUser || {}

  const db = getFirestore()
  const storage = getStorage()

  function useDocData(docRef) {
    const { path } = docRef
    const [snapshot, loading, error] = useDocument(docRef)

    useEffect(() => {
      if (loading) {
        if (!alertDialog.getIsOpen(path + '-loading')) {
          alertDialog.open({ isLoading: true }, path + '-loading')
        }
      } else if (error) {
        if (uid) {
          snackbar.open({ operation: 'データを取得', isError: true }, path + '-error')
        }
      } else if (!snapshot?.exists()) {
        snackbar.open({ title: 'データが存在しません', isError: true }, path + '-notfound')
      }

      if (!loading && alertDialog.getIsOpen(path + '-loading')) {
        alertDialog.close(path + '-loading')
      }
      if (!error) {
        snackbar.close(path + '-error')
      }
      if (snapshot?.exists()) {
        snackbar.close(path + '-notfound')
      }
    }, [snapshot, loading, error])

    return {
      data: getData(snapshot),
      loading,
      error,
    }
  }

  function useColData(colRef, ...options) {
    const { path } = colRef
    if (!options.length) {
      options.push(orderBy('createdAt', 'desc'))
    }
    const [snapshot, loading, error] = useCollection(query(colRef, ...options))

    useEffect(() => {
      if (loading) {
        if (!alertDialog.getIsOpen(path + '-loading')) {
          alertDialog.open({ isLoading: true }, path + '-loading')
        }
      } else if (error) {
        console.error(error)
        if (uid && !isAdmin) {
          snackbar.open({ operation: 'データを取得', isError: true }, path + '-error')
        }
      }

      if (!loading && alertDialog.getIsOpen(path + '-loading')) {
        alertDialog.close(path + '-loading')
      }
      if (!error) {
        snackbar.close(path + '-error')
      }
    }, [loading, error])

    return {
      dataList: snapshot?.docs?.map?.((docSnapshot) => getData(docSnapshot)) || [],
      loading,
      error,
    }
  }

  async function addDocData(colRef, data, operation, timeout = 0) {
    const loadingDialog = operation ? alertDialog.open({ operation, isLoading: true }) : null
    return new Promise((resolve) => {
      addDoc(colRef, {
        ...getParsedData(data),
        createdByUserId: uid,
        createdAt: serverTimestamp(),
      })
        .then((ret) => {
          resolve(ret.id)
          if (operation) {
            setTimeout(() => loadingDialog.close(), timeout)
            snackbar.open({ operation })
          }
        })
        .catch(() => {
          resolve(false)
          if (operation) {
            loadingDialog.close()
            snackbar.open({ operation, isError: true })
          }
        })
    })
  }

  async function setDocData(docRef, data, operation) {
    const loadingDialog = operation ? alertDialog.open({ operation, isLoading: true }) : null
    return new Promise((resolve) => {
      setDoc(docRef, {
        ...getParsedData(data),
        createdByUserId: uid,
        createdAt: serverTimestamp(),
      })
        .then(() => {
          resolve(true)
          if (operation) {
            loadingDialog.close()
            snackbar.open({ operation })
          }
        })
        .catch(() => {
          resolve(false)
          if (operation) {
            loadingDialog.close()
            snackbar.open({ operation, isError: true })
          }
        })
    })
  }

  async function updateDocData(docRef, data, operation) {
    const loadingDialog = operation ? alertDialog.open({ operation, isLoading: true }) : null
    return new Promise((resolve) => {
      updateDoc(docRef, {
        ...getParsedData(data),
        updatedByUserId: uid,
        updatedAt: serverTimestamp(),
      })
        .then(() => {
          resolve(true)
          if (operation) {
            loadingDialog.close()
            snackbar.open({ operation })
          }
        })
        .catch(() => {
          resolve(false)
          if (operation) {
            loadingDialog.close()
            snackbar.open({ operation, isError: true })
          }
        })
    })
  }

  async function deleteDocData(docRef, operation) {
    const loadingDialog = operation ? alertDialog.open({ operation, isLoading: true }) : null
    return new Promise((resolve) => {
      deleteDoc(docRef)
        .then(() => {
          resolve(true)
          if (operation) {
            loadingDialog.close()
            snackbar.open({ operation })
          }
        })
        .catch(() => {
          resolve(false)
          if (operation) {
            loadingDialog.close()
            snackbar.open({ operation, isError: true })
          }
        })
    })
  }

  async function listenDocData(docRef, callback) {
    onSnapshot(docRef, (snapshot) => {
      callback(getData(snapshot))
    })
  }

  const schoolColRef = collection(db, 'schools')
  const labColGroupRef = collectionGroup(db, 'labs')
  const mentorColRef = collection(db, 'mentors')
  const applicationColGroupRef = collectionGroup(db, 'applications')

  function useSchoolCol() {
    return useColData(schoolColRef)
  }

  function addSchool(data) {
    return addDocData(schoolColRef, data, '学校を新規登録')
  }

  function updateSchool(schoolId, data) {
    return updateDocData(doc(schoolColRef, schoolId), data, '学校を更新')
  }

  function deleteSchool(schoolId) {
    return deleteDocData(doc(schoolColRef, schoolId), '学校を削除')
  }

  function useMentorCol() {
    return useColData(mentorColRef)
  }

  function addMentor(data) {
    return addDocData(mentorColRef, data, 'メンターを新規登録')
  }

  function updateMentor(mentorId, data) {
    return updateDocData(doc(mentorColRef, mentorId), data, 'メンターを更新')
  }

  function deleteMentor(mentorId) {
    return deleteDocData(doc(mentorColRef, mentorId), 'メンターを削除')
  }

  const {
    dataList: [mySchoolDocData],
  } = useColData(schoolColRef, where('userIdArr', 'array-contains', uid || ''))
  const {
    dataList: [myLabDocData],
  } = useColData(labColGroupRef, where('userIdArr', 'array-contains', uid || ''))

  function useApplicationColGroup() {
    return useColData(applicationColGroupRef)
  }

  async function uploadImg(path, imgFile) {
    const operation = '画像をアップロード'
    return uploadBytes(ref(storage, path), imgFile)
      .then(() => {
        snackbar.open({ operation })
      })
      .catch((e) => {
        console.error(e)
        snackbar.open({ operation, isError: true })
      })
  }

  const currentUserRole = useMemo(() => {
    if (currentUser?.isAdmin) {
      return 'admin'
    }
    return mySchoolDocData?.userRoleObj?.[currentUser?.uid] || 'guest'
  }, [currentUser, mySchoolDocData])

  return {
    useDocData,
    useColData,
    addDocData,
    setDocData,
    updateDocData,
    deleteDocData,
    listenDocData,
    schoolColRef,
    mentorColRef,
    useSchoolCol,
    addSchool,
    updateSchool,
    deleteSchool,
    useMentorCol,
    addMentor,
    updateMentor,
    deleteMentor,
    useApplicationColGroup,
    mySchoolDocData,
    myLabDocData,
    currentUserRole,
    uploadImg,
  }
})

function getData(snapshot) {
  if (!snapshot) {
    return undefined
  }
  if (!snapshot.exists()) {
    return { notFound: true }
  }
  const data = snapshot.data()
  if (snapshot.ref.parent?.parent?.parent) {
    data.parentId = snapshot.ref.parent.parent.id
  }
  return { ...data, id: snapshot.id }
}

function getParsedData(data, nested) {
  if (typeof data !== 'object') {
    return data
  }
  if (Array.isArray(data)) {
    data.forEach((x, i) => {
      data[i] = getParsedData(x, true)
    })
    return data
  }
  for (const key in data) {
    if (data.hasOwnProperty(key)) {
      if (data[key] === undefined || (!nested && data[key]?.toDate)) {
        delete data[key]
      } else if (key === 'timestampKeyArr') {
        data[key].forEach((timestampKey) => {
          data[timestampKey] = serverTimestamp()
        })
        delete data[key]
      } else if (key.endsWith('Count')) {
        data[key] = Number(data[key])
      } else {
        data[key] = getParsedData(data[key], true)
      }
    }
  }
  return data
}
