import React, { useState } from 'react'
import { IconButton, Tooltip } from '@mui/material'
import AssignmentIcon from '@mui/icons-material/Assignment'
import CopyToClipboard from 'react-copy-to-clipboard'

export default (props) => {
  const { value } = props
  const [hasCopied, setHasCopied] = useState(false)

  return (
    <CopyToClipboard text={value} onCopy={() => setHasCopied(true)}>
      <Tooltip
        onClose={() => setTimeout(() => setHasCopied(false), 500)}
        placement='top'
        title={hasCopied ? 'コピーしました' : value}
        PopperProps={{ sx: { transition: 'none !important' } }}
        leaveDelay={400}
      >
        <IconButton>
          <AssignmentIcon />
        </IconButton>
      </Tooltip>
    </CopyToClipboard>
  )
}
