import { createContainer } from 'unstated-next'
import { doc } from 'firebase/firestore'

import Firestore from './Firestore'
import FirestoreSchool from './FirestoreSchool'

export default createContainer(({ applicationId }) => {
  const { useDocData, updateDocData } = Firestore.useContainer()
  const { applicationColRef } = FirestoreSchool.useContainer()

  const applicationDocRef = doc(applicationColRef, applicationId)
  const { data: docData = {} } = useDocData(applicationDocRef)

  function update(data, operation) {
    return updateDocData(applicationDocRef, data, operation)
  }

  return { docData, update }
})
