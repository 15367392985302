import { format } from 'date-fns'
import { ja as localeJa } from 'date-fns/locale'

export function toString(value) {
  if (!value?.toDate) {
    return null
  }
  return value.toDate().toLocaleString('ja').slice(0, -3)
}

export function toDateString(value) {
  if (!value?.toDate) {
    return null
  }
  return value.toDate().toISOString().slice(0, 10)
}

export function isFuture(value) {
  if (!value?.toDate) {
    return false
  }
  return value.toDate() > new Date()
}

export function isPast(value) {
  if (!value?.toDate) {
    return false
  }
  return value.toDate() < new Date()
}

export function isOld(value) {
  if (!value?.toDate) {
    return false
  }
  return value.toDate() - new Date() < -5 * 60 * 1000
}

export function diff(value1, value2) {
  if (!value1?.toDate || !value2?.toDate) {
    return null
  }
  return value1.toDate() - value2.toDate()
}

export const formatDateTimeRange = (slot) => {
  if (!slot.date || !slot.date.seconds) return ''

  try {
    const date = new Date(slot.date.seconds * 1000)
    const formattedDate = format(date, 'yyyy年M月d日(E)', { locale: localeJa })
    return `${formattedDate} ${slot.startTime}〜${slot.endTime}`
  } catch (error) {
    console.error('Error formatting date:', error)
    return '日付のフォーマット中にエラーが発生しました'
  }
}
