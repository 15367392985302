import React, { useEffect, useMemo, useState } from 'react'
import { Chip, Stack, Typography, List, ListItem, ListItemText } from '@mui/material'
import { doc } from 'firebase/firestore'

import Firestore from '../containers/Firestore'
import FirestoreSchool from '../containers/FirestoreSchool'
import FirestoreApplication from '../containers/FirestoreApplication'

import MentorCard from '../components/MentorCard'
import * as time from '../utils/time'
import ja from '../utils/ja.json'
import supportTypeList from '../utils/supportTypeList.json'
import meetingFormatList from '../utils/meetingFormatList.json'
import Card from '../elements/Card'

const ApplicationDetails = () => {
  const firestore = Firestore.useContainer()
  const firestoreSchool = FirestoreSchool.useContainer()
  const { docData: school = {} } = firestoreSchool
  const { dataList: labList = [] } = firestoreSchool.useLabCol()
  const { dataList: userList = [] } = firestoreSchool.useUserCol()
  const { docData: application = {} } = FirestoreApplication.useContainer()
  const [mentor, setMentor] = useState(undefined)
  const createdByUser = useMemo(
    () => userList.find((x) => x.id === application.createdByUserId),
    [userList, application.createdByUserId],
  )
  const createdByUserRole = useMemo(
    () => school.userRoleObj?.[application.createdByUserId],
    [school.userRoleObj, application.createdByUserId],
  )
  const targetLab = useMemo(
    () =>
      labList.find((x) => {
        if (createdByUserRole === 'student') {
          return x.userIdArr?.includes?.(application.createdByUserId)
        }
        return x.id === application.info?.labId
      }),
    [labList, createdByUserRole, application.createdByUserId, application.info?.labId],
  )
  const targetTeamUser = useMemo(
    () => userList.find((x) => x.id === application.info?.teamUserId),
    [userList, application.info?.teamUserId],
  )

  useEffect(() => {
    if (application.mentorId) {
      firestore.listenDocData(doc(firestore.mentorColRef, application.mentorId), setMentor)
    } else {
      setMentor(undefined)
    }
  }, [application.mentorId])

  return (
    <Stack spacing={3} py={3} maxWidth='md'>
      {!!application.mentorId && <MentorCard {...mentor} />}
      {!!createdByUser && (
        <Card sx={{ width: '100%' }}>
          <Stack spacing={2.5}>
            <Typography variant='body1' fontWeight='bold'>
              相談申込者
            </Typography>
            <Stack direction='row' spacing={2} alignItems='center'>
              <Chip label={ja[createdByUserRole]} variant='outlined' />
              <Typography variant='body2'>
                {createdByUser.info?.displayName}（
                {createdByUser.info?.email || createdByUser.info?.teamId}）
              </Typography>
            </Stack>
          </Stack>
        </Card>
      )}
      {!!application.info?.unit && (
        <Card sx={{ width: '100%' }}>
          <Stack spacing={2.5}>
            <Typography variant='body1' fontWeight='bold'>
              相談の単位
            </Typography>
            <Typography variant='body2'>{ja[application.info?.unit]}</Typography>
          </Stack>
        </Card>
      )}
      {!!targetLab && (
        <Card sx={{ width: '100%' }}>
          <Stack spacing={2.5}>
            <Typography variant='body1' fontWeight='bold'>
              {createdByUserRole === 'student' ? '所属ラボ' : '対象のラボ'}
            </Typography>
            <Stack spacing={2}>
              <Typography variant='body2'>{targetLab.info?.name}</Typography>
              <Typography variant='caption'>{targetLab.info?.description}</Typography>
            </Stack>
            <Typography variant='body1' fontWeight='bold'>
              担当教員
            </Typography>
            <Stack direction='row' spacing={2} flexWrap='wrap' useFlexGap>
              {targetLab.info?.teacherUserIdArr?.map((userId) => {
                const teacherUser = userList.find(({ id }) => id === userId)
                return (
                  <Chip
                    key={userId}
                    label={`${teacherUser?.info?.displayName}（${teacherUser?.info?.email}）`}
                    variant='outlined'
                  />
                )
              })}
            </Stack>
          </Stack>
        </Card>
      )}
      {!!targetTeamUser && (
        <Card sx={{ width: '100%' }}>
          <Stack spacing={2.5}>
            <Typography variant='body1' fontWeight='bold'>
              対象のチーム
            </Typography>
            <Typography variant='body2'>{targetTeamUser.info?.name}</Typography>
          </Stack>
        </Card>
      )}
      <Card sx={{ width: '100%' }}>
        <Stack spacing={2.5}>
          <Typography variant='body1' fontWeight='bold'>
            相談したい内容
          </Typography>
          {application.info?.description ? (
            <Typography variant='body2'>{application.info?.description}</Typography>
          ) : (
            <>
              <Stack spacing={2}>
                <Typography variant='body2' fontWeight='bold'>
                  探究テーマ
                </Typography>
                <Typography variant='body2'>{application.info?.researchTheme}</Typography>
              </Stack>

              <Stack spacing={2}>
                <Typography variant='body2' fontWeight='bold'>
                  希望する支援の種類
                </Typography>
                <List disablePadding>
                  {application.info?.supportTypes?.map((supportType, index) => (
                    <ListItem key={index} disableGutters>
                      <ListItemText
                        primary={
                          supportTypeList.find((item) => item.id === supportType)?.label ||
                          supportType
                        }
                        primaryTypographyProps={{ variant: 'body2' }}
                      />
                    </ListItem>
                  ))}
                </List>
              </Stack>

              <Stack spacing={2}>
                <Typography variant='body2' fontWeight='bold'>
                  支援内容の詳細
                </Typography>
                <Typography variant='body2'>{application.info?.supportDetails}</Typography>
              </Stack>
            </>
          )}
        </Stack>
      </Card>
      {application.info?.meetingFormat && (
        <Card sx={{ width: '100%' }}>
          <Stack spacing={2.5}>
            <Typography variant='body1' fontWeight='bold'>
              希望日時と形態
            </Typography>
            <Stack spacing={2}>
              <Typography variant='body2' fontWeight='bold'>
                希望形態
              </Typography>
              <Typography variant='body2'>
                {meetingFormatList.find((item) => item.id === application.info?.meetingFormat)
                  ?.label || application.info?.meetingFormat}
              </Typography>

              <Stack spacing={2}>
                <Typography variant='body2' fontWeight='bold'>
                  候補日時
                </Typography>
                <List disablePadding>
                  {application.info?.availableSlots
                    ?.filter((slot) => slot.date)
                    .map((slot, index) => (
                      <ListItem key={index} disableGutters>
                        <ListItemText
                          primary={`第${index + 1}希望: ${time.formatDateTimeRange(slot)}`}
                          primaryTypographyProps={{ variant: 'body2' }}
                        />
                      </ListItem>
                    ))}
                </List>
              </Stack>
            </Stack>
          </Stack>
        </Card>
      )}
      <Card sx={{ width: '100%' }}>
        <Stack spacing={2.5}>
          <Typography variant='body1' fontWeight='bold'>
            その他・補足事項
          </Typography>
          <Typography variant='body2'>{application.info?.note}</Typography>
        </Stack>
      </Card>
      <Card sx={{ width: '100%' }}>
        <Stack spacing={2.5}>
          <Typography variant='body1' fontWeight='bold'>
            提出日時
          </Typography>
          <Typography variant='body2'>{time.toString(application.createdAt)}</Typography>
        </Stack>
      </Card>
    </Stack>
  )
}

export default ApplicationDetails
