import React from 'react'
// import Firestore from "../containers/Firestore";
import {
  Paper,
  Stack,
  Typography,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from '@mui/material'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { useNavigate } from 'react-router-dom'

export default () => {
  const navigate = useNavigate()
  // const { mySchoolDocData: mySchool, currentUserRole } = Firestore.useContainer();

  return (
    <Stack spacing={2} maxWidth='md'>
      <Stack spacing={1}>
        <Typography variant='h5' fontWeight='bold' color='white.main'>
          管理画面
        </Typography>
        <Typography variant='caption' color='white.main'>
          この画面は生徒には表示されません。
        </Typography>
      </Stack>

      <List component={Paper} variant='outlined'>
        {list.map((item, i) => (
          <ListItem key={i} disablePadding>
            <ListItemButton onClick={() => navigate(item.to)}>
              <ListItemText primary={item.title} />
              <ChevronRightIcon color='border' />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Stack>
  )
}

const list = [
  { title: '提出済みの相談フォームを確認する', to: './applications' },
  { title: 'アカウントを管理する', to: './users' },
  { title: 'ラボを管理する／生徒を招待する', to: './labs' },
  { title: '教員を招待する', to: './invitations' },
]
