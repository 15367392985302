import React from 'react'
import { useNavigate } from 'react-router-dom'
import { FormContainer } from 'react-hook-form-mui'
import { Divider, Stack, Typography } from '@mui/material'

import AlertDialog from '../containers/AlertDialog'
import Firestore from '../containers/Firestore'
import FirestoreSchool from '../containers/FirestoreSchool'
import FirestoreMentor from '../containers/FirestoreMentor'

import Card from '../elements/Card'
import ApplicationFormContent from '../components/ApplicationFormContent'
import { MentorDetailHeader } from './MentorDetail'

export default () => {
  const navigate = useNavigate()
  const alertDialog = AlertDialog.useContainer()
  const { currentUserRole } = Firestore.useContainer()
  const firestoreSchool = FirestoreSchool.useContainer()
  const { docData: mentor = {} } = FirestoreMentor.useContainer()

  function handleSubmit(info) {
    info.availableSlots = info?.availableSlots?.filter((slot) => slot.date !== null)

    firestoreSchool.addApplication({ info, mentorId: mentor.id }).then(() => {
      setTimeout(() => {
        alertDialog.open({
          title: '相談フォームを提出しました',
          description: '運営が内容を確認し、担当の先生に連絡します。しばらくお待ちください。',
          disableOptionalClose: true,
          actions: [
            {
              label: 'OK',
              onClick: () => {
                switch (currentUserRole) {
                  case 'student':
                    navigate('/application/history')
                    break
                  case 'teacher':
                    navigate('/manage/applications')
                    break
                  case 'admin':
                    navigate('/admin/applications')
                    break
                  default:
                    navigate('/')
                }
              },
            },
          ],
        })
      }, 300)
    })
  }

  return (
    <Stack py={4} spacing={4} maxWidth='md'>
      <MentorDetailHeader {...mentor} />
      <Divider />
      <Stack spacing={2}>
        <Typography variant='h5' fontWeight='bold'>
          相談フォーム
        </Typography>
        <Typography variant='caption'>
          フォームに必要事項を記入し送信すると、運営にリクエストが届きます。運営が内容を確認して担当の先生に連絡を行い、機会のセッティングを行います。
          <br />
          回答から原則1週間以内に、運営から担当先生のメールアドレス宛に連絡します（要望に応えるのが難しい場合はその旨を伝達します）。
          <br />
          生徒の皆さんが記入する場合は、必ず担当の先生に確認してから行ってください。
          <br />
        </Typography>
      </Stack>
      <Card>
        <FormContainer onSuccess={handleSubmit}>
          <ApplicationFormContent />
        </FormContainer>
      </Card>
    </Stack>
  )
}
