import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { createContainer } from 'unstated-next'
import { doc, collection } from 'firebase/firestore'

import Firebase from './Firebase'
import Firestore from './Firestore'
import AlertDialog from './AlertDialog'
import Snackbar from './Snackbar'

export default createContainer(({ invitationCode = '' }) => {
  const firebase = Firebase.useContainer()
  const { useDocData, addDocData, listenDocData, schoolColRef } = Firestore.useContainer()
  const alertDialog = AlertDialog.useContainer()
  const snackbar = Snackbar.useContainer()
  const navigate = useNavigate()

  const [invitationId, schoolId, laboId] = invitationCode.split('-')

  const schoolDocRef = doc(schoolColRef, schoolId)
  const invitationDocRef = doc(
    laboId ? doc(schoolDocRef, 'labs', laboId) : schoolDocRef,
    'invitations',
    invitationId,
  )
  const requestColRef = collection(invitationDocRef, 'requests')

  const { data: docData = {}, error } = useDocData(invitationDocRef)

  useEffect(() => {
    if (error) {
      alertDialog.open({
        title: '無効な招待リンクです。',
        actions: [
          {
            label: 'TOPページへ',
            onClick: () => {
              navigate('/')
            },
          },
        ],
      })
    }
  }, [error])

  async function tryRequest(data) {
    return new Promise((resolve, reject) => {
      addDocData(requestColRef, data)
        .then((requestId) => {
          listenDocData(doc(requestColRef, requestId), (requestDocData) => {
            switch (requestDocData.status) {
              case 'successed':
                resolve(true)
                break
              case 'failed':
                switch (requestDocData.errorCode) {
                  case 'auth/email-already-exists':
                    alertDialog.open({
                      title: `この${requestDocData.info?.teamId ? 'チームID' : 'メールアドレス'}は既に使われています`,
                    })
                    break
                  // case "auth/invalid-email":
                  //   break;
                  // case "auth/invalid-password":
                  //   break;
                  default:
                    break
                }
                reject(false)
                break
              default:
                break
            }
          })
        })
        .catch(() => {
          reject(false)
        })
    })

    // const loadingDialog = alertDialog.open({ operation: "アカウントを作成", isLoading: true });
    // return firebase.signUp(data)
    //   .then((userCredential) => {
    //     console.log(userCredential);
    //     return addDocData(requestColRef, data);
    //   });
  }

  return { docData, tryRequest }
})
