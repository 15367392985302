import React, { useCallback } from 'react'
import {
  Button,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'

import AlertDialog from '../containers/AlertDialog'
import Drawer from '../containers/Drawer'
import Snackbar from '../containers/Snackbar'
import FirestoreSchool from '../containers/FirestoreSchool'

import InvitationDrawerContent from '../components/InvitationDrawerContent'

import MenuButton from '../elements/MenuButton'
import * as time from '../utils/time'
import CopyButton from '../elements/CopyButton'

export default () => {
  const alertDialog = AlertDialog.useContainer()
  const drawer = Drawer.useContainer()
  const snackbar = Snackbar.useContainer()
  const firestoreSchool = FirestoreSchool.useContainer()
  const { docData: school = {} } = firestoreSchool
  const { dataList: invitationList = [] } = firestoreSchool.useInvitationCol()

  const getInvitationUrl = useCallback(
    (invitationId) => `https://lab.e-donuts.com/invitations/${invitationId}-${school.id}`,
    [school.id],
  )

  function handleOpenDrawer(invitation) {
    const defaultValue = invitation
      ? { ...invitation.info, expiresAt: invitation.info.expiresAt?.toDate?.() }
      : undefined
    const btnLabel = defaultValue ? '更新' : '作成'
    drawer.open({
      title: `教員用の招待リンクを${btnLabel}`,
      btnLabel,
      ContentComponent: <InvitationDrawerContent {...{ defaultValue }} />,
      onSuccess: defaultValue ? handleUpdate : handleCreate,
    })

    async function handleCreate(info) {
      firestoreSchool.addInvitation({ info, schoolName: school.info?.name }).then(onWrite)
    }

    async function handleUpdate(info) {
      firestoreSchool.updateInvitation(invitation.id, { info }).then(() => onWrite(invitation.id))
    }

    function onWrite(invitationId) {
      drawer.close()
      navigator.clipboard.writeText(getInvitationUrl(invitationId))
      snackbar.open({ operation: '招待リンクをコピー' })
    }
  }

  async function handleDelete(invitation) {
    alertDialog.open({
      title: '招待リンクを削除しますか？',
      description: 'このリンクは無効になります。',
      cancelable: true,
      actions: [
        {
          label: '削除する',
          color: 'error',
          onClick: () => firestoreSchool.deleteInvitation(invitation.id),
        },
      ],
    })
  }

  return (
    <Stack spacing={2} py={2} maxWidth='xl'>
      <Button
        variant='contained'
        color='primary'
        onClick={() => handleOpenDrawer()}
        sx={{ alignSelf: 'center' }}
      >
        教員を招待する
      </Button>

      <TableContainer variant='outlined' component={Paper} sx={{ width: '100%' }}>
        <Table>
          <TableHead sx={{ '& th': { fontSize: 'small', color: 'gray', padding: '8px 16px' } }}>
            <TableRow>
              <TableCell>アカウント数</TableCell>
              <TableCell>有効期限</TableCell>
              <TableCell>作成日時</TableCell>
              <TableCell>招待リンク</TableCell>
              <TableCell sx={{ width: 0 }} />
            </TableRow>
          </TableHead>
          <TableBody>
            {invitationList.map((invitation) => (
              <TableRow key={invitation.id}>
                <TableCell>{`${invitation.count || 0} / ${invitation.info?.maxCount || '∞'}`}</TableCell>
                <TableCell
                  sx={{ color: time.isPast(invitation.info?.expiresAt) ? 'error.main' : 'inherit' }}
                >
                  {time.toString(invitation.info?.expiresAt)}
                </TableCell>
                <TableCell>{time.toString(invitation.createdAt)}</TableCell>
                <TableCell>
                  <CopyButton value={getInvitationUrl(invitation.id)} />
                </TableCell>
                <TableCell>
                  <MenuButton
                    items={[
                      {
                        label: '編集',
                        Icon: EditIcon,
                        color: 'primary',
                        onClick: () => handleOpenDrawer(invitation),
                      },
                      {
                        label: '削除',
                        Icon: DeleteForeverIcon,
                        color: 'error',
                        onClick: () => handleDelete(invitation),
                      },
                    ]}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
  )
}
