import React, { useState, useCallback, useEffect } from 'react'
import { Stack, Typography, FormHelperText } from '@mui/material'
import {
  CheckboxButtonGroup,
  TextareaAutosizeElement,
  RadioButtonGroup,
  SelectElement,
  DatePickerElement,
  useFormContext,
} from 'react-hook-form-mui'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { ja } from 'date-fns/locale'

import supportTypeList from '../utils/supportTypeList.json'
import meetingFormatList from '../utils/meetingFormatList.json'
import SubmitButton from '../elements/SubmitButton'

const timeOptions = Array.from({ length: 96 }, (_, i) => {
  const hours = Math.floor(i / 4)
  const minutes = (i % 4) * 15
  return {
    id: `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`,
    label: `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`,
  }
})

const defaultStartTime = '10:00'
const defaultEndTime = '11:30'

const minDate = new Date()
minDate.setDate(minDate.getDate() + 5)

const TimeSlotRow = React.memo(({ index, onValidate }) => {
  const { watch, setValue } = useFormContext()
  const date = watch(`availableSlots.${index}.date`)
  const startTime = watch(`availableSlots.${index}.startTime`)
  const endTime = watch(`availableSlots.${index}.endTime`)

  React.useEffect(() => {
    if (date && (!startTime || !endTime)) {
      setValue(`availableSlots.${index}.startTime`, defaultStartTime)
      setValue(`availableSlots.${index}.endTime`, defaultEndTime)
      onValidate(index, defaultStartTime, defaultEndTime)
    }
  }, [date, startTime, endTime, setValue, index, onValidate])

  const handleTimeChange = useCallback(
    (field, value) => {
      setValue(`availableSlots.${index}.${field}`, value)
      onValidate(
        index,
        field === 'startTime' ? value : startTime,
        field === 'endTime' ? value : endTime,
      )
    },
    [index, startTime, endTime, setValue, onValidate],
  )

  return (
    <Stack direction='row' spacing={2} alignItems='center'>
      <DatePickerElement
        name={`availableSlots.${index}.date`}
        label={`第${index + 1}候補`}
        inputProps={{
          size: 'small',
          sx: { width: '200px' },
        }}
        minDate={minDate}
        maxDate={new Date(new Date().setFullYear(new Date().getFullYear() + 1))}
      />
      <SelectElement
        name={`availableSlots.${index}.startTime`}
        label='開始時間'
        options={timeOptions}
        size='small'
        sx={{ width: '120px' }}
        disabled={!date}
        onChange={(value) => handleTimeChange('startTime', value)}
      />
      <Typography>～</Typography>
      <SelectElement
        name={`availableSlots.${index}.endTime`}
        label='終了時間'
        options={timeOptions}
        size='small'
        sx={{ width: '120px' }}
        disabled={!date}
        onChange={(value) => handleTimeChange('endTime', value)}
      />
    </Stack>
  )
})

const ApplicationFormContent = () => {
  const [errors, setErrors] = useState({})
  const {
    watch,
    setValue,
    formState: { isValid },
  } = useFormContext()

  const validateTimeRange = useCallback((index, startTime, endTime) => {
    if (startTime && endTime && startTime >= endTime) {
      setErrors((prev) => ({
        ...prev,
        [index]: '終了時間は開始時間より後である必要があります',
      }))
    } else {
      setErrors((prev) => {
        const newErrors = { ...prev }
        delete newErrors[index]
        return newErrors
      })
    }
  }, [])

  const isFormValid = useCallback(() => {
    return isValid && Object.keys(errors).length === 0
  }, [isValid, errors])

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name && name.startsWith('availableSlots') && name.endsWith('date') && type === 'change') {
        const index = parseInt(name.match(/\d+/)[0])
        setValue(`availableSlots.${index}.startTime`, defaultStartTime)
        setValue(`availableSlots.${index}.endTime`, defaultEndTime)
        validateTimeRange(index, defaultStartTime, defaultEndTime)
      }
    })
    return () => subscription.unsubscribe()
  }, [watch, setValue, validateTimeRange])

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ja}>
      <Stack spacing={4}>
        <Typography variant='h6'>Q1. 相談したい内容について教えてください</Typography>
        <TextareaAutosizeElement
          name='researchTheme'
          label='a. 探究テーマについて教えてください'
          required
          rows={3}
        />
        <CheckboxButtonGroup
          name='supportTypes'
          label='b. どういった種類の支援を求めているか教えてください（複数選択可）'
          options={supportTypeList}
          required
          parseError={() => 'この項目は必須です'}
        />
        <TextareaAutosizeElement
          name='supportDetails'
          label='c. 前の質問について、詳しく教えてください'
          rows={3}
        />

        <Typography variant='h6'>
          Q2.
          内容を確認したうえ、メンターや外部の方と日程調整を行います。調整をするうえで必要なことを事前に教えてください
        </Typography>

        <RadioButtonGroup
          name='meetingFormat'
          label='a. 希望形態を選んでください（単一選択）'
          options={meetingFormatList}
          required
          parseError={() => 'この項目は必須です'}
        />

        <Stack spacing={2}>
          <Typography>
            b. 現段階で、対象生徒が都合のつく日時を仮候補として3～5つ教えてください
          </Typography>
          <Typography variant='body2'>
            ※スムーズな日程調整のため、現時点での予定で構いませんので、できるだけ多くの候補日を教えてください
          </Typography>

          {[0, 1, 2, 3, 4].map((index) => (
            <React.Fragment key={index}>
              <TimeSlotRow index={index} onValidate={validateTimeRange} />
              {errors[index] && <FormHelperText error>{errors[index]}</FormHelperText>}
            </React.Fragment>
          ))}
        </Stack>

        <TextareaAutosizeElement name='note' label='c. その他・補足事項' rows={3} />

        <SubmitButton label='送信' sx={{ width: 'fit-content' }} disabled={!isFormValid()} />
      </Stack>
    </LocalizationProvider>
  )
}

export default ApplicationFormContent
