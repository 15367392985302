import React, { useEffect, useState } from 'react'
import { useFormState, useFormContext } from 'react-hook-form-mui'
import { Button } from '@mui/material'
import { isEqual } from 'lodash'

export default React.memo((props) => {
  const { label, defaultValue, ...restProps } = props
  const { isValid, errors } = useFormState()
  const { watch } = useFormContext()
  const [isDirty, setIsDirty] = useState(false)

  useEffect(() => {
    const subscription = watch((currentValue) => {
      // フィールドが変更されたかどうかを判断する
      setIsDirty(!isEqual(currentValue, defaultValue))
    })
    return () => subscription.unsubscribe()
  }, [watch, defaultValue])

  const hasErrors = React.useMemo(() => {
    const checkNestedErrors = (obj) => {
      return Object.values(obj).some((value) =>
        value && typeof value === 'object' ? checkNestedErrors(value) : value !== undefined,
      )
    }
    return checkNestedErrors(errors)
  }, [errors])

  return (
    <Button
      type='submit'
      variant='contained'
      color='primary'
      disabled={!isValid || !isDirty || hasErrors}
      {...restProps}
    >
      {label}
    </Button>
  )
})
