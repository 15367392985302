import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Routes, Route, useNavigate, useLocation, Link } from 'react-router-dom'
import { createContainer } from 'unstated-next'
import {
  Breadcrumbs,
  Drawer,
  Box,
  List,
  ListItemButton,
  ListItemText,
  IconButton,
  Stack,
  Typography,
  Avatar,
} from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft'

import { palette } from '../theme'
import routes from '../routes'

import logo from '../assets/logo.png'
import hero from '../assets/hero.png'

import Firebase from './Firebase'
import Firestore from './Firestore'
import Header from './Header'

export default createContainer(() => {
  const navigate = useNavigate()
  const location = useLocation()
  const header = Header.useContainer()
  const { currentUser } = Firebase.useContainer()
  const {
    currentUserRole,
    mySchoolDocData: mySchool,
    myLabDocData: myLab,
  } = Firestore.useContainer()

  const currentSegmentArr = useMemo(
    () => location.pathname.split('/').filter((x, i) => !!x || !i),
    [location.pathname],
  )
  const isAuth = useMemo(
    () => ['signin', 'invitations', 'reset-password'].includes(currentSegmentArr[1]),
    [currentSegmentArr],
  )

  const getIsRouteActive = useCallback(
    (route) => {
      return (
        (!route.minUserRole || roleOrderObj[route.minUserRole] <= roleOrderObj[currentUserRole]) &&
        (!route.maxUserRole || roleOrderObj[route.maxUserRole] >= roleOrderObj[currentUserRole])
      )
    },
    [currentUserRole],
  )

  const currentRoute = useMemo(() => getRouteFromSegmentArr(currentSegmentArr), [currentSegmentArr])

  const [prevRoute, setPrevRoute] = useState(currentRoute)
  const [isMenuOpen, setIsMenuOpen] = useState(true)

  useEffect(() => {
    document.body.style.backgroundColor =
      palette.background[
      currentRoute.backgroundType || (currentSegmentArr.length > 2 ? 'main' : 'sub')
      ]
  }, [currentSegmentArr.length])

  useEffect(() => {
    if (!currentRoute) {
      document.title = 'Donuts-Lab'
      return
    }
    document.title = `Donuts-Lab｜${currentRoute.title}`
    if (currentRoute.path !== prevRoute?.path) {
      setPrevRoute(currentRoute)
    }
  }, [currentRoute])

  // function setSegmentTitle(segment, title) {
  //   setSegmentTitleObj((prev) => ({ ...prev, [segment]: title }));
  // }

  const Component = (
    <>
      <Box
        my={`${outerMargin}px`}
        pl={`${isMenuOpen && !isAuth ? drawerWidth + outerMargin : 0}px`}
        pt={`${isAuth ? 0 : breadcrumbHeight}px`}
      >
        {header.Component}
        <Box mx={`${outerMargin}px`} px={`${containerPaddingX}px`}>
          <Routes>
            {routes.map((route) => (
              <Route
                key={route.path}
                path={route.path}
                element={getIsRouteActive(route) ? route.element : null}
                Component={getIsRouteActive(route) ? route.Component : null}
              />
            ))}
          </Routes>
        </Box>
      </Box>
      {!isAuth && (
        <>
          <Drawer
            open={isMenuOpen}
            onClose={() => setIsMenuOpen(false)}
            anchor='left'
            variant='persistent'
            hideBackdrop
            elevation={0}
            PaperProps={{
              sx: {
                m: `${outerMargin}px`,
                height: `calc(100dvh - ${outerMargin * 2}px)`,
                width: `${drawerWidth}px`,
                borderRadius: 4,
                border: '2px solid',
                borderColor: 'border.sub',
              },
            }}
          >
            <Stack direction='row'>
              <img
                src={logo}
                alt='Donuts-Lab'
                style={{ width: '164px', aspectRatio: 5.2, margin: '12px' }}
              />
              <IconButton onClick={() => setIsMenuOpen(false)} sx={{ px: 0 }}>
                <KeyboardDoubleArrowLeftIcon />
              </IconButton>
            </Stack>
            <List>
              {routes.map((route, i) =>
                !route.isPinned || !getIsRouteActive(route) ? null : (
                  <ListItemButton
                    key={i}
                    selected={
                      route.path === '/'
                        ? currentSegmentArr.length === 1
                        : location.pathname.startsWith(route.path)
                    }
                    onClick={() => navigate(route.path)}
                  >
                    <ListItemText
                      primary={route.title}
                      primaryTypographyProps={{ fontWeight: 'bold' }}
                    />
                  </ListItemButton>
                ),
              )}
            </List>
            {!!currentUser && (
              <Stack direction='row' spacing={1} p={2} sx={{ position: 'absolute', bottom: 0 }}>
                <Avatar
                  src={currentUser.photoURL}
                // alt={currentUser.displayName}
                />
                <Stack>
                  <Typography variant='subtitle2' fontWeight='bold'>
                    {currentUser.displayName}
                  </Typography>
                  {!!myLab && (
                    <Typography variant='caption' color='text.secondary'>
                      {myLab.info?.name}
                    </Typography>
                  )}
                  {!!mySchool && (
                    <Typography variant='caption' color='text.secondary'>
                      {mySchool.info?.name}
                    </Typography>
                  )}
                </Stack>
              </Stack>
            )}
          </Drawer>

          <IconButton
            onClick={() => setIsMenuOpen(true)}
            sx={{
              position: 'fixed',
              top: `${outerMargin}px`,
              left: `${outerMargin + containerPaddingX}px`,
              px: 0,
              zIndex: 200,
            }}
          >
            <MenuIcon color='white' />
          </IconButton>

          <Stack
            direction='row'
            bgcolor={currentRoute?.color ? `${currentRoute.color}.main` : 'transparent'}
            pl={`${(isMenuOpen ? drawerWidth : 24) + outerMargin * 2 + containerPaddingX}px`}
            py={1}
            height={`${breadcrumbHeight}px`}
            position='fixed'
            top={currentRoute?.color ? 0 : '-100px'}
            right={0}
            left={0}
            zIndex={100}
            alignItems='center'
          >
            <Breadcrumbs separator={<NavigateNextIcon fontSize='small' color='white' />}>
              {currentSegmentArr.map((segment, i) => {
                const segmentArr = currentSegmentArr.slice(0, i + 1)
                const route = getRouteFromSegmentArr(segmentArr)
                return (
                  <Link
                    key={i}
                    to={segmentArr.join('/')}
                    style={{ color: 'white', fontSize: '0.8rem' }}
                  >
                    {route?.title}
                  </Link>
                )
              })}
            </Breadcrumbs>
          </Stack>

          {currentSegmentArr.length === 1 && (
            <Box sx={{ position: 'fixed', top: 0, left: 0, right: 0, zIndex: -2 }} >
              <img src={hero} alt='' id='hero' style={{ width: '100%', height: 'auto' }} />
            </Box>
          )}
        </>
      )}

      <Box
        sx={{
          position: 'fixed',
          top: currentSegmentArr.length === 2 || currentRoute.isWaveShown ? 0 : '-50dvh',
          left: 0,
          right: 0,
          zIndex: -1,
          animationDelay: 5000,
        }}
      >
        <Box
          sx={{
            backgroundColor: palette[currentRoute?.color]?.main || 'transparent',
            height: { xs: '36dvh', sm: '18dvh', md: '6dvh', lg: 0 },
            width: '100%',
          }}
        />
        <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1512 392' fill='none'>
          <path
            fill={palette[currentRoute?.color]?.main || 'transparent'}
            d='M0 0H1512V362.825C1388 351.741 1317.45 277.086 1114 285.312C910.546 293.539 440.965 411.309 250.5 389.253C60.0349 367.197 0 337.461 0 337.461V0Z'
          />
        </svg>
      </Box>
    </>
  )

  return { Component }
})

const drawerWidth = 224
const breadcrumbHeight = 56
const outerMargin = 16
const containerPaddingX = 8

export const roleOrderObj = {
  guest: 0,
  student: 1,
  teacher: 2,
  // manager: 3,
  admin: 3,
}

function getRouteFromSegmentArr(segmentArr) {
  if (segmentArr.length === 1) {
    return routes[0]
  }
  return routes.find((route) =>
    segmentArr.join('/').match(new RegExp(`^${route.path.replace(/:[^/]+/, '[^/]+')}$`)),
  )
}
