import React, { useState } from 'react'
import { TextFieldElement } from 'react-hook-form-mui'
import { IconButton, InputAdornment } from '@mui/material'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'

export default (props) => {
  const { isSignIn, isConfirm } = props
  const [isVisible, setIsVisible] = useState(false)

  return (
    <TextFieldElement
      name={isConfirm ? 'passwordConfirm' : 'password'}
      label={isConfirm ? 'パスワード（確認）' : 'パスワード'}
      type={isVisible ? 'text' : 'password'}
      required
      fullWidth
      autoComplete={isSignIn ? 'current-password' : isConfirm ? null : 'new-password'}
      InputProps={{
        endAdornment: (
          <InputAdornment position='end'>
            <IconButton onClick={() => setIsVisible((prev) => !prev)}>
              {isVisible ? <VisibilityIcon /> : <VisibilityOffIcon />}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  )
}
